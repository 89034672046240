.recent-title {
  color: var(--color-solid-darkblue);
}

.recent-title > p {
  color: var(--color-solid-darkergrey);
  padding-left: 20px;
}

.recent-table {
  width: 100%;
  margin: 20px 0;
}
.recent-table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #d5d5d5;
}

.recent-table td:last-child {
  text-align: right;
}

.recent-table td:nth-last-child(2) {
  text-align: right;
}

.recent-table td:nth-last-child(3) {
  text-align: right;
  color: var(--color-solid-darkergrey);
}

.recent-table tr {
  margin: 5px;
}
