.login-form {
  background: var(--color-solid-white);
  width: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 18px;
}

.login-bg {
  /* background:
        linear-gradient(to left top, rgb(222, 247, 247) 0%,
        rgba(245, 219, 219, 0.801) 50% ,
        rgba(237, 252, 237, 0.87) 80%),
        linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));     */
  /* min-height: 100vh; */
  position: relative;
  /* background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red); */
  /* background: conic-gradient(from 90deg, #fff, #000); */
  width: inherit;
  /* background: var(--color-gradient-bg); */
}

.registernow {
  color: var(--color-solid-darkblue);
  font-weight: 500;
}

.login-input {
  padding: 12px 20px !important;
}

.forgot-psw-link {
  font-weight: 500;
  font-size: 12px;
  color: var(--color-solid-darkgrey);
  cursor: pointer;
}

.forgot-psw-link:hover {
  color: var(--color-solid-darkblue);
}

.signin-btn {
  height: 40px;
  border-radius: 10px;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input[type="password"]:-webkit-autofill,
input[type="password"]:-webkit-autofill:hover,
input[type="password"]:-webkit-autofill:focus,
input[type="password"]:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (max-width: 1200px) {
  .login-form {
    background: var(--color-solid-white);
    width: 70%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .login-form {
    background: var(--color-solid-white);
    width: 90%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 18px;
  }
}

/* @media only screen and (max-width: 576px) {
  .login-form {
    background: var(--color-solid-white);
    width: 100%;
    border-radius: 18px;
  }
} */

/* .login-page .ant-form-item-explain-error {
  padding: 7px 10px 10px;
} */
