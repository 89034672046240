.system-setup1 .create-formitem-div {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 5px 20px;
  overflow-y: auto;
}

.system-setup1 .view-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 5px;
  overflow: auto;
}

.contract-table {
  margin-top: 30px;
}

.heading-div {
  margin: 15px 0px 0px 10px;
}

.head-div {
  margin: 5px 0px 0px 10px;
}

.org-contract-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px 22px;
  height: calc(100vh - 105px);
  overflow: auto;
}

.view-div .ant-menu-item:hover,
.pipeline-view-div .ant-menu-item:hover {
  background-color: transparent !important;
}

.view-div {
  background-color: var(--color-solid-backgroundgrey);
  /* height: calc(100vh - 105px); */
  overflow-x: hidden;
}

.view-div .org-view-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 5px;
  height: calc(100vh - 203px);
  overflow: hidden;
}

.view-div .contract-info-view,
.pipeline-view-div .contract-info-view {
  padding: 5px 0px 5px 5px;
  margin-left: 20px;
  height: calc(100vh - 235px);
  overflow: auto;
}

.view-div .documents-div .notes-div,
.pipeline-view-div .pipeline-documents-div .notes-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: 8px;
  left: 7px;
  min-width: 99%;
}

.view-div .documents-div .notes-icons,
.pipeline-view-div .pipeline-documents-div .notes-icons {
  display: flex;
  margin-left: auto;
}

.view-div .org-view-div .document-item,
.pipeline-view-div .pipeline-org-view .document-item {
  border-bottom: var(--color-solid-lightgrey) solid 2px;
}

.view-div .org-view-div .document-item-align,
.pipeline-view-div .pipeline-org-view .document-item-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contract-preview {
  border: 0.1rem solid var(--color-border-grey) !important;
  background-color: var(--color-solid-white);
  width: 50%;
  padding: 4rem 4.5rem;
  min-height: calc(90vh - 150px);
}

.tablist-div {
  margin-left: 25%;
  border-radius: 10px !important;
  background-color: var(--color-solid-backgroundgrey);
}

.list-div {
  display: flex;
  justify-content: space-around;
}

.card-header {
  background-color: var(--color-solid-white);
  border-radius: 8px;
  height: 40px;
}

.headerlist {
  padding: 6px;
}
