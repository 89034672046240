.ant-select-selection-item .ant-checkbox {
  display: none;
}

.ant-select-dropdown .ant-select-item-option-state {
  display: none;
}
.light-text {
  color: rgb(255, 0, 0); 
}
