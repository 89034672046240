/* tabs starts */

.contract .contract-tree-tab .ant-tabs-nav-wrap {
  justify-content: center;
}

.contract .contract-tree-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.contract .contract-tree-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.contract .contract-tree-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.contract .contract-tree-tab .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
}

.contract .contract-tree-tab .ant-tabs-tab {
  padding: 0 !important;
}

.contract .contract-tree-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.contract .contract-tree-tab .ant-tabs-tab-btn {
  width: 180px;
}

.contract-tree-tab .ant-tabs-tab .ant-image {
  display: none;
}
.contract-tree-tab .ant-tabs-tab-active .ant-image {
  display: inline-block;
}

.contract-tree-tab .ant-tabs-tab .title {
  display: inline-flex;
}

.contract-tree-tab .ant-tabs-tab .title .ant-image {
  margin-right: 10px !important;
  margin-top: -1px;
}

/* .contract-tree-tab .ant-tabs-tab .tab-title {
  padding-top: 0.3em;
} */

/* tabs ends */

/* ContractTreeTab */

#contracts-tree-main-tab .ant-tabs-nav {
  background: var(--color-solid-white);
  margin-bottom: 5px;
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-backgroundgrey);
  border: none;
  margin-left: 25px;
  transition: none;
  padding: 5px 20px 5px 15px;
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active:before,
#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 10px;
  box-shadow: -10px 0 0 0 var(--color-solid-backgroundgrey);
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 10px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-backgroundgrey);
}

#contracts-tree-main-tab .ant-tabs-tab {
  border: none;
  background: var(--color-solid-white);
  margin-left: 20px;
  transition: none;
  padding: 5px 15px 5px 15px;
}

#contracts-tree-main-tab .ant-tabs-tab-disabled {
  border: none;
  background: var(--color-solid-white);
  margin-left: 20px;
  margin-bottom: 1px;
  transition: none;
  padding: 0;
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-nav-add {
  border: none;
  color: var(--color-solid-darkgrey);
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-nav-add > span > svg {
  width: 15px;
  height: 15px;
  box-shadow: var(--color-solid-darkergrey);
}

#contracts-tree-main-tab .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-solid-darkergrey);
  text-shadow: none;
}

.contracts-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 45px;
}

.contracts-tab-title .tab-title-image {
  padding-right: 15px;
}

/* .contract-tree-tab .ant-tabs-tab .tab-title {
  padding-top: 0.3em;
} */

/* tabs ends */

.file-view-div {
  padding: 0 30px;
}

.approval-link-page {
  background-color: var(--color-solid-backgroundgrey);
  /* padding-top: 5px; */
}
