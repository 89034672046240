#contract-layout {
  background-color: var(--color-solid-backgroundgrey);
  padding: 8px 20px;
  height: calc(100vh - 87px);
}

#contract-pipeline-layout {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 50px);
  padding: 8px 20px;
}

#contract-info-card {
  height: 32.433px !important;
  box-shadow: none !important;
}

#contract-info-card .ant-card-body {
  height: 100% !important;
  padding: 5px 6px 4px 6px !important;
}

#contract-info-card .role-name-text {
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 12px;
}

#contract-info-card #contract-header-text {
  color: var(--color-solid-darkgrey);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}

#contract-view-tab.contract-form-tab
  .ant-tabs-content-holder
  .ant-tabs-tabpane {
  padding: 0 !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-nav-list {
  margin: 0 !important;
  display: flex;
  align-items: center !important;
  width: 80px !important;
}

#contract-pipeline-layout #contract-view-tab.contract-form-tab .ant-tabs-nav {
  margin: 0 !important;
  padding: 0 !important;
  height: calc(100vh - 107px) !important;
  background-color: #efefef !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

#contract-layout #contract-view-tab.contract-form-tab .ant-tabs-nav {
  margin: 0 !important;
  padding: 0 !important;
  height: calc(100vh - 145px) !important;
  background-color: #efefef !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-nav-wrap {
  background-color: #efefef !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-nav-operations {
  margin: 0 !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: unset !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef !important;
  margin: 0 !important;
  min-width: 80px !important;
  min-height: 90px !important;
  border-radius: 0 !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-tab:first-child {
  border-top-left-radius: 8px !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-tab:not(:first-child) {
  border-top: 1px solid var(--color-border) !important;
}

#contract-view-tab.contract-form-tab .ant-tabs-tab-active {
  background: var(--color-solid-white) !important;
  border-radius: 0 !important;
  min-width: 80px !important;
  min-height: 90px !important;
}

/* #contract-layout #contract-view-tab .ant-tabs-nav-list,
#contract-pipeline-layout #contract-view-tab .ant-tabs-nav-list {
  margin: 0 !important;
}

#contract-layout #contract-view-tab .ant-tabs-nav,
#contract-pipeline-layout #contract-view-tab .ant-tabs-nav {
  background-color: var(--color-solid-backgroundgrey) !important;
  margin: 0 !important;
  padding: 0 !important;
}

#contract-view-tab .ant-tabs-nav-wrap {
  background-color: var(--color-solid-backgroundgrey) !important;
}

#contract-refactor-tab #contract-view-tab .ant-tabs-nav-operations {
  margin-right: 0px !important;
}

#contract-view-tab .ant-tabs-extra-content {
  width: 25vw !important;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--color-solid-backgroundgrey) !important;
  border: none !important;
  transition: none !important;
  margin: 0 !important;
  padding: 8px 16px !important;
}

#contract-view-tab .ant-tabs-tab-btn .tabs-ss-title {
  color: var(--color-solid-darkergrey) !important;
  text-shadow: 0 0 1px var(--color-solid-darkgrey) !important;
  display: flex !important;
  align-items: center !important;
}

#contract-view-tab .ant-tabs-tab-btn .tabs-ss-title:hover {
  color: var(--color-solid-darkerblue) !important;
  text-shadow: 0 0 0.8px currentColor !important;
}

#contract-view-tab .ant-tabs-tab-active .ant-tabs-tab-btn .tabs-ss-title {
  text-shadow: 0 0 0.8px currentColor !important;
  color: var(--color-solid-darkerblue) !important;
}

#contract-view-tab .ant-tabs-tab-active {
  background-color: var(--color-solid-white) !important;
  border: none !important;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-white) !important;
  border: none !important;
  transition: none !important;
  padding: 8px 16px !important;
  border-radius: 8px 8px 0 0 !important;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab-active:before,
#contract-view-tab .ant-tabs-nav .ant-tabs-tab-active:after,
#contract-view-tab .ant-tabs-nav .ant-tabs-tab:before,
#contract-view-tab .ant-tabs-nav .ant-tabs-tab:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px !important;
  border-radius: 0 0 0 5px !important;
  box-shadow: -10px 0 0 0 var(--color-solid-white) !important;
  z-index: 999;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab-active:before {
  left: -20px !important;
  border-radius: 0 0 5px 0 !important;
  box-shadow: 10px 0 0 0 var(--color-solid-white) !important;
  z-index: 999;
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-backgroundgrey);
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-backgroundgrey);
}

#contract-view-tab .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0 !important;
  margin-left: 3px;
}

#contract-view-tab .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

#contract-view-tab .ant-tabs-ink-bar {
  display: none !important;
} */

#contract-layout #contract-info-menu,
#contract-pipeline-layout #contract-info-menu {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

#contract-layout #contract-info-menu {
  height: calc(100vh - 219px) !important;
}

#contract-pipeline-layout #contract-info-menu {
  height: calc(100vh - 183px) !important;
}

#contract-layout .ant-menu-vertical,
#contract-pipeline-layout .ant-menu-vertical {
  border-inline-end: 2px solid var(--color-solid-lightgrey) !important;
}

#contract-layout .ant-menu-item .ant-menu-item-icon + span,
#contract-pipeline-layout .ant-menu-item .ant-menu-item-icon + span {
  transition: none !important;
}

#contract-layout .ant-menu-item,
#contract-pipeline-layout .ant-menu-item {
  color: var(--color-solid-darkergrey) !important;
  padding: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-inline: 24px !important;
  margin-block: 4px !important;
  /* padding-inline: 12px !important; */
  transition: none !important;
}

#contract-layout .ant-menu-item-selected,
#contract-pipeline-layout .ant-menu-item-selected {
  /* font-weight: 600; */
  text-shadow: 0 0 0.8px currentColor;
  background-color: transparent !important;
  color: var(--color-solid-darkblue) !important;
  padding: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-inline: 0px !important;
  margin-block: 4px !important;
  /* padding-inline: 0px !important; */
  transition: none !important;
}

#contract-layout .ant-menu-item:hover,
#contract-pipeline-layout .ant-menu-item:hover {
  background-color: transparent !important;
  color: var(--color-solid-darkergrey) !important;
  text-shadow: 0 0 0.8px currentColor;
}

#contract-layout .ant-menu-item-selected:hover,
#contract-pipeline-layout .ant-menu-item-selected:hover {
  background-color: transparent !important;
  color: var(--color-solid-darkblue) !important;
  text-shadow: 0 0 0.8px currentColor;
}

#contract-layout .org-view-div,
#contract-pipeline-layout .org-view-div {
  background-color: var(--color-solid-white);
  border-radius: 0 10px 10px 0px;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

#contract-layout .org-view-div {
  height: calc(100vh - 145px);
}

#contract-pipeline-layout .org-view-div {
  height: calc(100vh - 107px);
}

#contract-layout .org-view-div .document-item,
#contract-pipeline-layout .org-view-div .document-item {
  border-bottom: var(--color-solid-lightgrey) solid 2px;
}

#contract-layout .org-view-div .document-item-align,
#contract-pipeline-layout .org-view-div .document-item-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

#contract-layout .contract-info-view,
#contract-pipeline-layout .contract-info-view {
  padding: 5px 0px 5px 5px;
  /* margin-left: 20px; */
  overflow: auto;
}

#contract-layout .contract-info-view {
  height: calc(100vh - 219px);
}

#contract-pipeline-layout .contract-info-view {
  height: calc(100vh - 183px);
}

.error-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
}

.error-list {
  margin-left: 20px;
  list-style: none;
  padding: 0;
}

.error-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px 0;
  background-color: #fff;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
}

.contract-main-div {
  background: var(--color-solid-white);
}

.doc-viewer #msdoc-renderer {
  height: calc(100vh - 230px) !important;
}

.status-arrow.draft::before {
  /* content: ""; */
  position: absolute;
  left: 0;
  top: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #f5f5f5;
  z-index: 1;
}

/* .contract-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */

.status-arrow {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  position: relative;
  background: var(--color-solid-lightgreen);
  color: #fff;
  /* font-family: Arial, sans-serif; */
  font-size: 12px;
  font-weight: 600;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%);
  box-sizing: border-box;
}

.status-arrow p {
  color: var(--color-solid-darkestgrey);
}

.status-green {
  background: var(--color-solid-lightgreen);
}

.status-amber {
  background: var(--color-light-orange);
}

.status-grey {
  background: var(--color-solid-lightgrey);
}

.contract-status-popup {
  position: fixed;
  top: 130px;
  /* right: 5%; */
  z-index: 1000;
  height: 100%;
  width: 10%;
}

.contract-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-step,
.status-step.collapsed {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.status-step:first-child {
  padding: 20px 0px 30px 0px;
}

.status-step:not(:first-child) {
  padding: 30px 0;
}

.status-step.collapsed {
  padding: 20px 0px;
}

.status-icon-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-solid-backgroundgrey);
  color: var(--color-solid-darkgrey) !important;
}

.status-icon-container.current {
  background-color: #55c91f;
  color: white !important;
}

.status-icon {
  width: 24px;
  height: 24px;
}

.connector-line {
  width: 3px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--color-solid-backgroundgrey);
  position: absolute;
  top: -20px;
}

.status-label {
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  color: var(--color-solid-darkgrey);
  text-align: center;
}

.current-status {
  font-weight: bold;
  color: var(--color-solid-darkestgrey);
}

/* Hide labels and lines in collapsed state */
.collapsed .status-label {
  display: none;
}

.collapsed .connector-line {
  display: none;
}

/* Hamburger Menu */
.hamburger-menu {
  cursor: pointer;
  font-size: 20px;
  color: var(--color-solid-darkergrey);
  text-align: center;
}
