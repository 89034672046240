@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

/* variables css*/
:root {
  --color-solid-white: #ffffff;
  --color-solid-whitegrey: #f7f7f7;
  --color-solid-black: #000000;
  --color-solid-lightgrey: #e2dfdf;
  --color-solid-backgroundgrey: #e4e4e4;
  --color-solid-darkgrey: #9b9b9b;
  --color-solid-darkergrey: #626166;
  --color-solid-darkestgrey: #474747;
  --color-solid-darkblue: #4285f4;
  --color-solid-darkestblue: #19b2ff;
  --color-solid-backgroundblue: #ddeefd;
  --color-solid-darkerblue: #0177fb;
  --color-solid-lightblue: #63c6ff;
  --color-solid-lightestblue: #2684ff;
  --color-solid-blue: #0092ff;
  --color-solid-red: #db4437;
  --color-solid-yellow: #f4b400;
  --color-solid-darkyellow: #fab401;
  --color-solid-lightyellow: #ffef5a;
  --color-solid-green: #0f9d58;
  --color-solid-lightgreen: #4ee79d;
  --color-solid-switchgreen: #03a600;
  --color-success: rgba(3, 168, 0, 1);
  --color-solid-orange: #fc7f1b;
  --color-light-orange: #f1ac73;
  --color-solid-purple: #665cfb;
  --color-shade-yellow: #f9d67d;
  --color-shade-red: #ff7a6f;
  --color-shade-orange: #ffbf8c;
  --color-shade-purple: #b8b2ff;
  --color-shade-blue: #e5f2ff;
  --color-lightshade-white: #fef4ec;
  --color-lightshade-blue: #ddf4ff;
  --color-lightshade-black: #334151;
  --color-lightshade-grey: #f1f0ff;
  --color-lightshade-orange: #fef4ec;
  --color-gradient-blue: linear-gradient(90deg, #4285f4 0%, #63c6ff 100%);
  --color-gradient-red: linear-gradient(90deg, #db4437 0%, #ff7a6f 100%);
  --color-gradient-yellow: linear-gradient(90deg, #f4b400 0%, #f9d67d 100%);
  --color-gradient-orange: linear-gradient(90deg, #fc7f1b 0%, #ffbf8c 100%);
  --color-gradient-purple: linear-gradient(90deg, #665cfb 0%, #b8b2ff 100%);
  /* --color-gradient-bg: linear-gradient(to right, #03A80020, #0177FB20, #F2473B20, #F9BE3920); */
  /* --color-gradient-bg: linear-gradient(to right, #03A80010, #0177FB10, #F2473B10, #F9BE3910); */
  --color-gradient-bg: linear-gradient(
    to right,
    rgba(3, 168, 0, 0.1),
    rgba(1, 119, 251, 0.1),
    rgba(242, 71, 59, 0.1),
    rgba(249, 190, 57, 0.1)
  );
  --color-card-glass: linear-gradient(
    110.14deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(246, 246, 246, 0.35) 78.44%
  );
  --color-glass-grey: linear-gradient(
    105.38deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(246, 246, 246, 0.35) 100%
  );
  --color-border: #d9d9d9;
  --color-table-data: #545454;
  --color-border-grey: rgb(194 192 192);
}

/* Gradient background */
.gradient-bg {
  background-image: var(--color-gradient-bg);
  /* filter: blur(500px); */
  /* opacity: 0.1; */
  min-height: 100vh;
}

/* padding & margin */
.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

/*Custom Scroll bar css */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-solid-darkgrey);
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-solid-darkergrey);
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.ant-table-body::-webkit-scrollbar-track {
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: var(--color-solid-darkgrey);
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-solid-darkergrey);
  border-left: 5px solid transparent;
  border-radius: 50px;
  transform: rotateX(180deg);
}

/* Alignment */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.overflow-hidden {
  overflow: hidden;
}

.vertical-align {
  vertical-align: middle !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-click {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.self-stretch-col {
  display: inline-flex;
  align-self: stretch;
}

.gradient-btn,
.gradient-btn:hover {
  background: var(--color-gradient-blue);
  border: 1px solid var(--color-gradient-blue);
}

.gradient-btn span,
.gradient-btn:hover span {
  color: var(--color-solid-white);
}

.trans-btn,
.trans-btn:hover {
  background-color: transparent;
  padding: 0px 16px 0px 16px;
  border: 1px solid var(--color-solid-darkblue) !important;
}

.trans-btn span {
  padding: 0px 25px 0px 25px;
  color: var(--color-solid-darkblue) !important;
}

.trans-btn:hover span {
  padding: 0px 25px 0px 25px;
  color: var(--color-solid-white) !important;
}

.blue-btn,
.blue-btn:hover {
  background-color: var(--color-solid-darkblue);
  padding: 0px 16px 0px 16px;
  border: 1px solid var(--color-solid-darkblue);
}

.blue-btn span,
.blue-btn:hover span {
  padding: 0px 20px 0px 20px;
  color: var(--color-solid-white) !important;
}

.lightblue-btn,
.lightblue-btn:hover {
  background-color: var(--color-shade-blue);
  border: 1px solid var(--color-shade-blue);
}

.lightblue-btn span,
.lightblue-btn:hover span {
  color: var(--color-solid-darkblue) !important;
}

.grey-btn {
  background-color: var(--color-lightshade-grey);
  border: 1px solid var(--color-lightshade-grey) !important;
}

.grey-btn span {
  color: var(--color-solid-darkblue) !important;
}

.content-padding {
  padding: 0 30px;
}

.ant-select-disabled .ant-select-selector {
  color: rgba(0, 0, 0, 0.9) !important;
  cursor: default !important;
}

.ant-select-disabled .ant-select-arrow {
  display: none !important;
}

.ant-input-disabled {
  background-color: transparent !important;
  color: var(--color-solid-black) !important;
  cursor: default !important;
}

.ant-modal {
  z-index: 9999;
}

.page-loader {
  display: grid;
  place-items: center;
  height: 100vh;
}
