.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f0f2f5;
}

.form-container {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.sign-document-btn {
  background-color: var(--color-solid-lightgrey);
}

.doc-upload-btn .ant-upload-select {
  display: block;
  width: 100%;
}
