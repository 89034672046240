.quill {
  border: 1px solid #0092ff;
  border-radius: 6px;
  height: 150px;
}

.view-quill {
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
}

.ql-toolbar {
  border: none !important;
  border-bottom: 1px solid #0092ff !important;
}

.ql-container {
  border: none !important;
}

#max-quill .ql-container {
  height: calc(100vh - 188px);
}

#max-quill-view .ql-container {
  height: calc(100vh - 148px);
}

#min-quill .ql-editor {
  height: 107px;
}

#max-quill .ql-editor {
  height: calc(100vh - 188px);
}

.ql-formats:hover {
  background-color: var(--color-solid-backgroundgrey);
  border-radius: 4px;
  cursor: pointer;
}

.maximize-batch-content .ant-modal-close {
  z-index: 999 !important;
  background-color: #0177fb !important;
}

.maximize-batch-content .ant-modal-close .exit-fullscreen-icon {
  background-color: #0177fb;
  color: #fff;
}

.maximize-batch-content .ant-modal-close:hover,
.maximize-batch-content .ant-modal-close:hover .exit-fullscreen-icon,
.maximize-batch-content .ant-modal-close:hover .exit-fullscreen-icon:hover {
  background-color: #0092ff !important;
}
