.data-table .ant-table-body {
  /* overflow: auto; */
  /* Enable scrolling */
}
.data-table .ant-table-body::-webkit-scrollbar {
  width: 10px; /* Thinner scrollbar width */
  height: 10px; /* For horizontal scrollbar */
}
.data-table .ant-table-body::-webkit-scrollbar-thumb {
  background-color: rgba(121, 119, 119, 0.703); /* Lighter color */
  border-radius: 50px; /* Rounded corners */
}
.data-table .ant-table-body::-webkit-scrollbar-track {
  background-color: rgba(211, 208, 208, 0.1); /* Very light track */
}
.data-table .ant-table-body table {
  border-spacing: 0 0px !important;
  margin-top: 0px !important;
  overflow: visible !important;
  max-height: none !important; /* Ensure the height is not limited by max-height */
  height: auto !important;
}
/* .ant-table-cell-scrollbar,
.data-table .ant-table-thead > tr > th:last-child {
  display: none !important;
} */

/* .data-table .ant-table-thead > tr > th:nth-last-child(2) {
  border-top-right-radius: 10px !important; /* Round the top-right corner */

/* .ant-table-cell-scrollbar {
  border-radius: 100px;
} */

.data-table .ant-table-thead > tr > th {
  background: rgb(5, 60, 109) !important;
  color: rgb(255, 255, 255) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 12px !important;
  border: 1px solid var(--color-solid-black) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  white-space: nowrap;
  text-align: center !important;
} /* this part used for th com,nots&comments, entity log, */

.data-table .ant-table-tbody {
  /* background-color: rgb(242, 242, 242); */
}
.data-table .ant-table-tbody > tr > td {
  text-align: center !important;
  white-space: nowrap;
  padding: 0px;
}
.data-table .ant-table-tbody .ant-table-cell:not(:first-child) {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
}
.data-table .ant-table-tbody .ant-table-cell:first-child {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.data-table .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0px !important;
}

.data-table .ant-table-tbody > tr:last-child > .ant-table-cell:first-child {
  border-bottom-left-radius: 10px !important;
} /*entity log*/

.data-table .ant-table-tbody > tr:last-child > .ant-table-cell:last-child {
  border-bottom-right-radius: 10px !important;
} /* entity log*/

.data-table .ant-table-tbody .ant-table-cell {
  font-size: 12px !important;
  /* font-weight: 400 !important; */
  font-weight: normal;
  /* color: red; */
  padding: 4px 14px !important;
  color: var(--color-solid-darkestgrey);
}
/* .data-table .ant-table-tbody > tr,
.ant-table-cell-fix-right:nth-child(odd) {
  background-color: #c04d4d;
} */
.data-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgb(242, 242, 242) !important; /* even row in light color*/
}
.data-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: rgb(212, 212, 212) !important; /*odd row in dark color*/
}

/*sdfhasdfhoaufhoah*/
#data-table-form .ant-table-thead > tr > th:not(:last-child),
#setup-data-table-form .ant-table-thead > tr > th:not(:last-child) {
  border-inline-end: 0px solid var(--color-border) !important;
}

#data-table-form .ant-table-tbody .ant-table-cell:not(:first-child),
#setup-data-table-form .ant-table-tbody .ant-table-cell:not(:first-child) {
  /* border-top: 1px solid var(--color-solid-black) !important; */
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  padding: 0 !important;
}

#data-table-form .ant-table-tbody .ant-table-cell:first-child {
  /* border-top: 1px solid var(--color-solid-black) !important; */
  padding: 0 !important;
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

#setup-data-table-form .ant-table-tbody .ant-table-cell:first-child {
  /* border-top: 1px solid var(--color-solid-black) !important; */
  padding: 1px !important;
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  border-top-left-radius: 0 !important;
}

#data-table-form .ant-table-tbody .ant-table-cell:last-child,
#setup-data-table-form .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0px !important;
}

#setup-data-table-form
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:first-child {
  border-bottom-left-radius: 10px !important;
}

#setup-data-table-form
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:last-child {
  border-bottom-right-radius: 10px !important;
}

#data-table-form .ant-table-summary .ant-table-cell:not(:first-child),
#setup-data-table-form .ant-table-summary .ant-table-cell:not(:first-child) {
  text-align: start !important;
}

#data-table-form .ant-table-summary .ant-table-cell:not(:last-child),
#setup-data-table-form .ant-table-summary .ant-table-cell:not(:last-child) {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

#data-table-form .ant-table-summary .ant-table-cell:first-child,
#setup-data-table-form .ant-table-summary .ant-table-cell:first-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-left-radius: 10px !important;
}

#data-table-form .ant-table-summary .ant-table-cell:last-child,
#setup-data-table-form .ant-table-summary .ant-table-cell:last-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-right: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-right-radius: 10px !important;
}

#data-table-form .ant-table-tbody > tr > td .ant-form-item,
#setup-data-table-form .ant-table-tbody > tr > td .ant-form-item {
  margin: 0 !important;
  height: 30px !important;
}

#data-table-form .ant-input,
#data-table-form .ant-input-number,
#setup-data-table-form .ant-input,
#setup-data-table-form .ant-input-number {
  height: 28px !important;
  border: none !important;
  font-size: 12px !important;
  /* font-weight: 100 !important; */
  background-color: transparent;
}

#data-table-form .ant-select,
#setup-data-table-form .ant-select {
  height: 30px !important;
  font-size: 12px !important;
  color: red;
  /* font-weight: 100 !important; */
}

#data-table-form
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
#setup-data-table-form
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-inline-end: 5px !important;
  text-align: justify;
}

#data-table-form .ant-select .ant-select-arrow,
#data-table-form .ant-select .ant-select-clear,
#setup-data-table-form .ant-select .ant-select-arrow,
#setup-data-table-form .ant-select .ant-select-clear {
  inset-inline-end: 5px !important;
}

#data-table-form .ant-select-selector,
#setup-data-table-form .ant-select-selector {
  padding: 0px 0px 0px 5px !important;
  height: 30px !important;
  border: none !important;
  font-size: 12px !important;
  background-color: transparent !important;
  /* font-weight: 100 !important; */
}

#data-table-form .ant-select-selector .ant-select-selection-search,
#setup-data-table-form .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 5px !important;
}

#data-table-form .ant-select-selector label,
#setup-data-table-form .ant-select-selector label {
  font-size: 12px !important;
  font-weight: 400 !important;
}

#data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#data-table-form .ant-table-tbody > tr > td .ant-picker,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#setup-data-table-form .ant-table-tbody > tr > td .ant-picker {
  height: 28px !important;
  border: none !important;
  background-color: transparent !important;
}

#data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-picker
  .ant-picker-input
  > input,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-picker
  .ant-picker-input
  > input {
  font-size: 12px !important;
  /* font-weight: 100 !important; */
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none; /* Removes the default content */
  background: none; /* Optionally, remove any background styles */
}

/* Data table with form.item css ends */

/* Data */

/* OLD TABLE CSS

.data-table .ant-table-body table {
  border-spacing: 0 0px !important;
  margin-top: 0px !important;
}

.data-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkestgrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 12px !important;
  border: 1px solid var(--color-solid-black) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  white-space: nowrap;
  text-align: center !important;
}

/* .ant-table-thead > tr > th {
  background-color: rgb(5, 60, 109);
} */

/* .data-table .ant-table-thead > tr > th:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.data-table .ant-table-tbody > tr > td {
  text-align: left !important;
  white-space: nowrap;
}

.data-table .ant-table-tbody .ant-table-cell:not(:first-child) {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
}

.data-table .ant-table-tbody .ant-table-cell:first-child {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.data-table .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0px !important;
}

.data-table .ant-table-tbody > tr:last-child > .ant-table-cell:first-child {
  border-bottom-left-radius: 10px !important;
}

.data-table .ant-table-tbody > tr:last-child > .ant-table-cell:last-child {
  border-bottom-right-radius: 10px !important;
}

.data-table .ant-table-tbody .ant-table-cell {
  font-size: 12px !important;
  font-weight: 100 !important;
  padding: 4px 14px !important;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkestgrey);
}

.data-table .ant-table-cell .name {
  text-align: left !important;
  padding-left: 10px;
} */

/* Data table with form.item css starts */
/* #data-table-form,
#setup-data-table-form {
  width: auto !important;
} */

/* #data-table-form .ant-table-wrapper .ant-table,
#setup-data-table-form .ant-table-wrapper .ant-table {
  width: auto !important; /* Ensure table fits the content width */
/* } */

/* #data-table-form .ant-table-wrapper .ant-table-content,
#setup-data-table-form .ant-table-wrapper .ant-table-content {
  width: auto !important; /* Ensure table content fits the specified widths */
/* } */

/* #data-table-form .ant-table-wrapper .ant-table-thead > tr > th,
#data-table-form .ant-table-wrapper .ant-table-tbody > tr > td,
#setup-data-table-form .ant-table-wrapper .ant-table-thead > tr > th,
#setup-data-table-form .ant-table-wrapper .ant-table-tbody > tr > td {
  white-space: nowrap; /* Prevent text from wrapping */
/* } */

/* #data-table-form .ant-table-thead > tr > th,
#setup-data-table-form .ant-table-thead > tr > th {
  /* background: var(--color-lightshade-blue) !important; */
/* color: var(--color-solid-darkestgrey) !important; */
/* text-transform: capitalize !important; */
/* font-weight: 600; */
/* font-size: 13px; */
/* padding: 4px 15px !important; */
/* border: 1px solid var(--color-solid-black) !important; */
/* border-bottom-left-radius: 0 !important; */
/* border-bottom-right-radius: 0 !important; */
/* } */

/* #data-table-form .ant-table-tbody .ant-table-cell,
#setup-data-table-form .ant-table-tbody .ant-table-cell {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  /* background-color: var(--color-solid-white); */
/* background-color: rgb(242, 242, 242) !important; */
/* color: var(--color-solid-darkestgrey); */
/* } */

/* #data-table-form .ant-table-tbody .ant-table-cell,
#setup-data-table-form .ant-table-tbody .ant-table-cell:nth-child(even) {
  background-color: rgb(212, 212, 212);
} */

/* #data-table-form .ant-table-body table,
#setup-data-table-form .ant-table-body table {
  border-spacing: 0 0px !important;
  margin-top: 0px !important;
} */

/* #data-table-form .ant-table-thead > tr > th,
#setup-data-table-form .ant-table-thead > tr > th {
  /* background: var(--color-lightshade-blue) !important; */
/* background-color: rgb(5, 60, 109) !important; */
/* color: rgb(255, 255, 255) !important; */
/* color: var(--color-solid-darkestgrey) !important; */
/* color: var(--color-solid-darkergrey) !important; */
/* text-transform: capitalize !important; */
/* font-weight: 600; */
/* font-size: 13px; */
/* text-align: center; */
/* padding: 10px !important; */
/* border: 5px solid var(--color-solid-black); */
/* border-bottom-left-radius: 0 !important; */
/* border-bottom-right-radius: 0 !important; */
/* } */
/*  */
/* #data-table-form .ant-table-thead > tr > th:not(:last-child),
#setup-data-table-form .ant-table-thead > tr > th:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
} */

/* #data-table-form .ant-table-tbody .ant-table-cell:not(:first-child),
#setup-data-table-form .ant-table-tbody .ant-table-cell:not(:first-child) {
  /* border-top: 1px solid var(--color-solid-black) !important; */
/* border-bottom: 1px solid var(--color-solid-black) !important; */
/* border-left: 1px solid var(--color-solid-black) !important; */
/* padding: 0 !important; */
/* } */

/* #data-table-form .ant-table-tbody .ant-table-cell:first-child {
  /* border-top: 1px solid var(--color-solid-black) !important; */
/* padding: 0 !important; */
/* border-bottom: 1px solid var(--color-solid-black) !important; */
/* border-left: 1px solid var(--color-solid-black) !important; */
/* border-top-left-radius: 0 !important; */
/* border-bottom-left-radius: 0 !important; */
/* } */

/* #setup-data-table-form .ant-table-tbody .ant-table-cell:first-child {
  /* border-top: 1px solid var(--color-solid-black) !important; */
/* padding: 1px !important; */
/* border-bottom: 1px solid var(--color-solid-black) !important; */
/* border-left: 1px solid var(--color-solid-black) !important; */
/* border-top-left-radius: 0 !important; */
/* } */

/* #data-table-form .ant-table-tbody .ant-table-cell:last-child,
#setup-data-table-form .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0px !important;
} */

/* #setup-data-table-form
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:first-child {
  border-bottom-left-radius: 10px !important;
} */

/* #setup-data-table-form
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:last-child {
  border-bottom-right-radius: 10px !important;
} */

/* #data-table-form .ant-table-summary .ant-table-cell:not(:first-child),
#setup-data-table-form .ant-table-summary .ant-table-cell:not(:first-child) {
  text-align: start !important;
} */

/* #data-table-form .ant-table-summary .ant-table-cell:not(:last-child),
#setup-data-table-form .ant-table-summary .ant-table-cell:not(:last-child) {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
} */

/* #data-table-form .ant-table-summary .ant-table-cell:first-child,
#setup-data-table-form .ant-table-summary .ant-table-cell:first-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-left-radius: 10px !important;
} */

/* #data-table-form .ant-table-summary .ant-table-cell:last-child,
#setup-data-table-form .ant-table-summary .ant-table-cell:last-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-right: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-right-radius: 10px !important;
} */

/* #data-table-form .ant-table-tbody > tr > td .ant-form-item,
#setup-data-table-form .ant-table-tbody > tr > td .ant-form-item {
  margin: 0 !important;
  height: 30px !important;
} */

/* #data-table-form .ant-input,
#data-table-form .ant-input-number,
#setup-data-table-form .ant-input,
#setup-data-table-form .ant-input-number {
  height: 28px !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 100 !important;
} */
/* 
#data-table-form .ant-select,
#setup-data-table-form .ant-select {
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 100 !important;
} */

/* #data-table-form
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
#setup-data-table-form
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-inline-end: 5px !important;
} */

/* #data-table-form .ant-select .ant-select-arrow,
#data-table-form .ant-select .ant-select-clear,
#setup-data-table-form .ant-select .ant-select-arrow,
#setup-data-table-form .ant-select .ant-select-clear {
  inset-inline-end: 5px !important;
} */

/* #data-table-form .ant-select-selector,
#setup-data-table-form .ant-select-selector {
  padding: 0px 0px 0px 5px !important;
  height: 30px !important;
  border: none !important;
  font-size: 12px !important;
  background-color: transparent !important;
  font-weight: 100 !important;
} */

/* #data-table-form .ant-select-selector .ant-select-selection-search,
#setup-data-table-form .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 5px !important;
} */

/* #data-table-form .ant-select-selector label,
#setup-data-table-form .ant-select-selector label {
  font-size: 12px !important;
  font-weight: 100 !important;
} */

/* #data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#data-table-form .ant-table-tbody > tr > td .ant-picker,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#setup-data-table-form .ant-table-tbody > tr > td .ant-picker {
  height: 28px !important;
  border: none !important;
  background-color: transparent !important;
} */
/* 
#data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-picker
  .ant-picker-input
  > input,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-form-item-control-input-content
  .ant-picker,
#setup-data-table-form
  .ant-table-tbody
  > tr
  > td
  .ant-picker
  .ant-picker-input
  > input {
  font-size: 12px !important;
  font-weight: 100 !important;
} */
/* Data table with form.item css ends */

/* Data */
