.react-flow-main {
  height: calc(100vh - 199px);
}

.react-flow-pipeline,
.react-flow-create-contract {
  height: calc(100vh - 163px);
}

.approver-node {
  min-width: 155px;
  max-height: 155px;
  border-radius: 10px;
  /* font-family: "Heebo", sans-serif; */
  padding: 0;
  overflow-wrap: break-word;
  text-align: left;
}

.react-flow-main .avatar,
.react-flow-pipeline .avatar {
  background-color: #fff !important;
  position: sticky;
  top: 1px;
  border: none !important;
}

.react-flow-main .email-text,
.react-flow-pipeline .email-text {
  font-size: 18px !important;
  color: var(--color-solid-blue);
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.react-flow-main .node-text,
.react-flow-pipeline .node-text {
  font-size: 18px !important;
  color: var(--color-solid-darkergrey);
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.react-flow-main .flow-text > p,
.react-flow-pipeline .flow-text > p {
  font-size: 13px !important;
  /* font-family: "Heebo", sans-serif !important; */
}

.react-flow-main .flow-text > p > b,
.react-flow-pipeline .flow-text > p > b {
  color: #333 !important;
}

.flow-text > p {
  color: var(--color-solid-darkestgrey) !important;
}

.approver-list {
  border: 1px solid var(--color-border);
  border-radius: 12px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.approver-list .user-approver-tag {
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  border: 1px solid var(--color-solid-green);
}

.approver-list .group-approver-tag {
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  border: 1px solid var(--color-solid-orange);
}

.approver-list .user-approver-tag .ant-tag-close-icon,
.approver-list .group-approver-tag .ant-tag-close-icon {
  margin-inline-start: 10px;
}

.approver-list .select-btn,
.approver-list .select-btn:hover {
  height: 30px;
  background: transparent;
  border: 1px solid var(--color-solid-darkgrey);
  color: var(--color-solid-darkgrey);
  border-radius: 12px;
  font-weight: 500;
  box-shadow: none;
}

.approver-list .selected-btn,
.approver-list .selected-btn:hover {
  height: 30px;
  border: 1px solid var(--color-solid-darkblue);
  background: var(--color-solid-darkblue) !important;
  border-radius: 12px;
  font-weight: 500;
  box-shadow: none;
}

.approver-editor-head {
  font-weight: 400;
  font-size: 13px;
  color: var(--color-solid-darkgrey);
}

.approver-editor-save {
  font-weight: 500;
  font-size: 13px;
  color: var(--color-solid-darkblue);
  cursor: pointer;
}

.approver-edit-modal .ant-modal-content {
  border-radius: 24px !important;
}

.user-select-option-type {
  border: 1px solid var(--color-solid-green);
  border-radius: 10px;
  padding: 0px 13px;
  margin-right: 10px;
  color: var(--color-solid-darkgrey);
  font-size: 12px;
}

.group-select-option-type {
  border: 1px solid var(--color-solid-orange);
  border-radius: 10px;
  padding: 0px 10px;
  margin-right: 10px;
  color: var(--color-solid-darkgrey);
  font-size: 12px;
}
