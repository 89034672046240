.contract .content-div {
  margin: 15px;
}

.glass-div {
  border-radius: 15px;
  border: none;
  margin: 0;
  padding: 15px !important;
  /* border: 1px solid var(--color-border); */
  /* background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  ); */
  color: var(--color-solid-darkgrey);
  /* max-height: 67vh;
    min-height: 67vh; */
  overflow: scroll;
  background-color: var(--color-solid-white);
}

.contract .breadcrumb-row {
  padding: 2px 15px 7px 20px;
}

.contract .breadcrumb {
  border-radius: 7px;
  /* border: 1px solid var(--color-border); */
  /* margin-bottom: 20px; */
  padding: 5px !important;
  /* border: 1px solid var(--color-border); */
  /* background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  ); */
  color: var(--color-solid-darkgrey);
  background-color: var(--color-solid-white);
}

/* .contract .screen-sizer {
  border-radius: 15px;
  border: 1px solid var(--color-border);
  margin-bottom: 20px;
  margin-left: 5px;
  padding: 11px !important;
  text-align: center;
  border: 1px solid var(--color-border);
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  color: var(--color-solid-darkgrey);
} */

.contract .breadcrumb button {
  padding: 5px 11px;
  line-height: 14px;
  border-radius: 100%;
  border: 0;
  margin: 0 10px;
  background-color: var(--color-lightshade-grey);
}

.contract .breadcrumb button .anticon {
  font-size: 10px;
  font-weight: bold;
  color: var(--color-solid-black);
}

.contract .breadcrumb-path {
  margin-left: 25px;
}

.contract .table-row {
  padding-left: 20px;
  padding-right: 10px;
}

.contract .tree-div {
  height: calc(100vh - 60px);
}
/*
.contract .tree-div .ant-tree-title {
  margin-top: 2px;
  display: inline-block;
  font-size: 15px;
}

.contract .tree-div .ant-tree {
  background-color: transparent;
  max-width: 100%;
}

.contract .tree-div .ant-tree .ant-tree-treenode {
  padding: 0 0 10px 0;
  width: 100%;
  display: inherit;
  white-space: nowrap;
}

.contract .tree-div .ant-tree .ant-tree-treenode span {
  display: inline-block;
}

.contract .tree-div .ant-tree .ant-tree-node-content-wrapper {
  min-width: 80%;
}

.contract .tree-div .ant-tree .ant-tree-node-content-wrapper .ant-tree-title {
  width: calc(100% - 24px);
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  span:first-child {
  display: inline-block;
  width: 100%;
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  .title-div
  span {
  display: inline-block;
  width: auto !important;
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  .title-div
  span:has(.title-div) {
  display: inline-block;
  width: 100% !important;
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  .title-div
  span
  .title-div {
  margin-left: 0 !important;
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  .tree-item-selected {
  font-size: 14px;
  color: var(--color-solid-black);
  padding-left: 7px;
}

.contract
  .tree-div
  .ant-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title
  .tree-item {
  font-size: 14px;
  color: var(--color-solid-darkergrey);
  padding-left: 7px;
}

.contract .tree-div .ant-tree .ant-tree-node-content-wrapper .pin {
  float: right;
}

.contract .tree-div .ant-tree-node-selected {
  width: 100% !important;
}

.contract .tree-div .ant-tree .ant-tree-switcher {
  padding-top: 3px;
} */
/* 
  .ant-tree-treenode:before {
      content: "";
      width: 20px;
      height: 20px;
      background:  url("../images/icons/selected_arrow.svg") no-repeat;
      top: 2px;
      left: 100%;
      position: absolute;
      display: inline-block;
  } */

.contract-tree-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkgrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.contract-tree-table .ant-table-thead > tr > th {
  /* border-start-start-radius: 0 !important; */
  border-inline-end: none !important;
  padding: 10px 20px;
}

.contract-tree-table .ant-table .ant-table-body table {
  border-spacing: 0 8px;
  margin-top: -5px;
}

.contract-tree-table .ant-table-container,
.contract-tree-table .ant-table table {
  border: 0 !important;
}

.contract-tree-table
  .ant-table
  .ant-table-body
  .ant-table-tbody
  .ant-table-row {
  background: var(--color-solid-white);
}

.contract-tree-table .ant-table-tbody > tr > td {
  color: var(--color-solid-black);
  font-size: 14px;
  text-align: left;
  border: none !important;
  padding: 0px 20px !important;
}

.contract-tree-table .ant-table {
  background: transparent !important;
}
.contract-tree-table td:first-child,
.contract-tree-table th:first-child {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.contract-tree-table thead tr td:last-child {
  display: none !important;
}

.contract-tree-table .ant-table-thead > tr > th:nth-child(5) {
  border-radius: 0 12px 12px 0 !important;
}

.contract-tree-table td:last-child,
.contract-tree-table th:last-child,
.contract-tree-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.contract-tree-table .name {
  display: table;
  text-align: left;
  /* margin: 0 20px; */
}

.contract-tree-table .name span {
  display: table-cell;
  vertical-align: middle;
}

.contract-tree-table .name span:last-child {
  padding-top: 3px;
  padding-left: 5px;
}

.contract .ant-layout {
  background: transparent !important;
}

.contract .path-text {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  color: #474747;
}
.contract .path-text:hover {
  color: var(--color-solid-darkblue);
}
.contract .path-icon .anticon {
  margin-right: 10px;
  font-size: 12px;
}
.tree-div {
  /* margin: 20px; */
  background: var(--color-solid-backgroundgrey);
}

.parent-contract-title {
  color: var(--color-solid-darkergrey);
  margin: 10px 10px 15px 5px;
}

.tree-div .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.tree-div .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0;
}

.tree-div .ant-pagination {
  display: flex !important;
  align-items: center !important;
}

.tree-div .ant-pagination .ant-pagination-options .ant-select,
.tree-div
  .ant-pagination
  .ant-pagination-options
  .ant-select
  .ant-select-selector {
  height: 32px !important;
  width: 100px !important;
}
