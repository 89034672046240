.basic-input {
  width: 100% !important;
  height: 42px !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--color-solid-lightgrey) !important;
  /* border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0 !important; */
  /* border: 1px solid var(--color-solid-darkgrey);
  border-radius: 10px; */
}

.basic-input .ant-select-selector {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--color-solid-lightgrey) !important;
}

.basic-input-textarea {
  /* width: 90% !important; */
  /* height: 42px !important; */
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--color-solid-lightgrey);
}

.basic-number-input {
  width: 90% !important;
  height: 42px !important;
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 10px;
}

.basic-number-input:hover {
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 10px;
}

.basic-number-input .ant-input-number-input-wrap {
  padding-top: 5px;
  padding-left: 5px;
}

.basic-number-input .ant-input-number-handler {
  border-inline-start: none;
}

.basic-number-input .ant-input-number-handler-wrap {
  width: 30% !important;
  border-radius: 10px;
  opacity: 100 !important;
}

/* .old-days-input { */
/* border-radius: 0px 0px 38px 0px; */
/* } */

/* .old-days
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input {
  border: 1px solid #d9d9d9 !important;
}

.days-form .ant-form-item .ant-form-item-control-input {
  margin: -3px !important;
  border: 1px solid #d9d9d9 !important;
} */

.ci-lists {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}

.ci-lists li {
  text-align: center;
  padding: 10px 30px;
  margin: 0px 20px;
  /* border-bottom: 2px solid var(--color-solid-darkblue); */
}

.form-outlet {
  border: 1px solid var(--color-lightshade-grey);
  padding: 20px;
  margin: 10px;
  background: var(--color-card-glass);
  border-radius: 12px;
}

.form-header {
  color: var(--color-solid-darkblue);
  font-weight: 700;
}

.line {
  border-width: 0;
  height: 1px;
  background-color: #cccccc;
}

.tabs-ss-title {
  display: flex;
  padding: 0px 10px 0px 10px;
}

.tabs-ss-title > img {
  /* padding-right: 10px; */
  width: 20px;
  height: 20px;
}

.tabs-ss-title > p {
  padding-left: 10px;
  font-size: 13px;
}

.create-doc {
  background-color: var(--color-solid-backgroundgrey);
}

.create-doc .ant-tabs .ant-tabs-tab-btn .tabs-ss-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.create-doc .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn .tabs-ss-title {
  font-weight: 600;
  color: var(--color-solid-darkblue);
  text-shadow: none;
}

.tabs-title {
  display: flex;
  padding: 0px 30px 0px 30px;
}

.tabs-title > img {
  /* padding-right: 10px; */
  width: 20px;
  height: 20px;
}

.tabs-title > p {
  padding-left: 10px;
  font-size: 13px;
}

.create-doc .ant-tabs-nav {
  background-color: var(--color-solid-white);
  margin: 0;
}

.create-doc .ant-tabs-nav-wrap {
  /* margin-top: 10px; */
  padding-left: 20px;
  background-color: var(--color-solid-backgroundgrey);
}

.create-doc .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--color-solid-backgroundgrey);
  border: none;
  transition: none;
}

.create-doc .ant-tabs .ant-tabs-tab-btn .tabs-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.create-doc .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn .tabs-title {
  font-weight: 600;
  color: var(--color-solid-black);
  text-shadow: none;
}

.create-doc .ant-tabs .ant-tabs-tab-active {
  background-color: var(--color-solid-white) !important;
  border: none;
}

.create-doc .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-backgroundgrey);
  border: none;
  /* margin-right: 10px; */
  transition: none;
}

.create-doc .ant-tabs-nav .ant-tabs-tab-active:before,
.create-doc .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.create-doc .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-white);
}

.create-doc .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-white);
}

.create-doc .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0;
}

.create-doc .ant-tabs-content-holder {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 136px);
  margin-top: -10px;
}

.create-doc .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.create-doc .outer-tab-div {
  background-color: var(--color-solid-white);
  height: calc(100vh - 156px);
  border-radius: 0px 20px 20px 20px;
  margin: 10px 20px;
}

.create-doc .inner-tab-div {
  margin: 10px 20px;
  padding: 10px 0px 10px 0px;
  height: calc(100vh - 165px);
  overflow-y: hidden;
}

.create-doc .form-div {
  padding-left: 10px;
  height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;
}

.create-doc .ant-menu {
  height: calc(100vh - 175px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.create-doc .ant-menu-vertical {
  border-inline-end: 2px solid var(--color-solid-lightgrey) !important;
}

.create-doc .ant-menu-item {
  color: var(--color-solid-darkergrey) !important;
}

.create-doc .ant-menu-item-only-child {
  margin-left: 35px !important;
  padding: 0 !important;
}

.create-doc .ant-menu-light .ant-menu-item-selected {
  background-color: transparent !important;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
  padding: 0 !important;
}

.create-doc .ant-menu-item:hover {
  background-color: transparent !important;
}

.contract-info-view
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon,
.org-supplier-label .ant-form-item-label .ant-form-item-no-colon {
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey) !important;
}

.create-doc .inner-tab-div .org-supplier-label .ant-input {
  padding: 8px 15px 10px 15px;
}

.create-doc .inner-tab-div .org-supplier-label .ant-select-selector {
  padding: 0px 0px 0px 20px !important;
  height: 42px !important;
}

.create-doc
  .inner-tab-div
  .org-supplier-label
  .ant-select-selection-search-input {
  padding: 10px 0px 0px 10px !important;
}

.create-doc .inner-tab-div .org-supplier-label .ant-checkbox-wrapper {
  padding-top: 0px !important;
}

.create-doc
  .inner-tab-div
  .org-supplier-label
  .ant-select-selection-placeholder {
  font-weight: lighter;
}

/* .create-doc .ant-tabs-nav-wrap {
  justify-content: center;
}

.create-doc .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.create-doc .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.create-doc .ant-tabs-nav::before {
  border-bottom: none !important;
}

.create-doc .ant-tabs-nav-list {
  border: none;
  background-color: var(--color-lightshade-white);
  border-radius: 24px;
}

.create-doc .ant-tabs-tab {
  padding: 0 !important;
}

.create-doc .ant-tabs-ink-bar {
  background-color: transparent !important;
} */

.doc-btn {
  /* display: flex;*/
  padding-right: 20px;
  padding-bottom: 5px;
  /* margin-top: auto; */
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-solid-backgroundgrey);
}

.form-subtitle {
  display: flex;
  justify-content: space-between;
}

.add-icon {
  height: 50%;
  filter: invert(45%) sepia(26%) saturate(2627%) hue-rotate(194deg)
    brightness(99%) contrast(93%);
  cursor: pointer;
}

/* Dynamic Table Form */
.create-table .ant-table-thead > tr > th {
  background: transparent !important;
  color: var(--color-solid-darkgrey) !important;
  text-transform: capitalize !important;
  border-bottom: 3px solid var(--color-border) !important;
  font-weight: 700;
  text-align: center;
}
.create-table .ant-table-cell-row-hover {
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  ) !important;
}
.create-table .ant-table-tbody > tr > td {
  color: var(--color-solid-black);
  font-size: 12px;
  text-align: center;
  border-bottom: none !important;
  background-color: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  ) !important;
}
.create-table .ant-table {
  background: transparent !important;
}
.create-table .ant-table-thead > tr > td {
  border-bottom: 3px solid var(--color-border) !important;
  background: transparent !important;
}
.create-table .ant-checkbox .ant-checkbox-inner {
  background-color: transparent !important;
  border: 2px solid var(--color-border);
  width: 19px !important;
  height: 19px !important;
}

/* Button */

.full-button {
  width: 100%;
}

.half-button {
  width: 50%;
  height: 38px;
}

.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}

.tab-content {
  z-index: 1;
  /* margin-top: 150px; */
  overflow-y: scroll;
  /* height: calc(100vh - 150px); */
}

.tab-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Dynamic Table Form */

.create-table .ant-table-thead > tr > th {
  background: transparent !important;
  color: var(--color-solid-darkgrey) !important;
  text-transform: capitalize !important;
  border-bottom: 3px solid var(--color-border) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  font-weight: 700;
  font-size: 11px;
}

.create-table .ant-table .ant-table-body table {
  border-spacing: 0 10px;
}

.create-table .ant-table {
  border: 0 !important;
}

.create-table .ant-table-container,
.create-table .ant-table table {
  border: 0 !important;
}

.create-table .ant-table .ant-table-body .ant-table-tbody .ant-table-row {
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 15px !important;
}

.create-table .ant-table-tbody > tr > td {
  color: var(--color-solid-black);
  font-size: 12px;
  text-align: center;
  border: none !important;
}

.create-table .ant-table {
  background: transparent !important;
}

.create-table .ant-table-thead > tr > td {
  border-bottom: 3px solid var(--color-border) !important;
  background: transparent !important;
}

.create-table .ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border: 2px solid var(--color-border);
  width: 19px !important;
  height: 19px !important;
}

.create-table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-solid-darkblue) !important;
}

.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}

.create-contract-content {
  z-index: 1;
  /* margin-top: 14rem; */
  overflow-y: scroll;
  /* height: calc(100vh - 14rem); */
  height: calc(100vh - 270px);
}

.create-doc .ant-layout {
  background: transparent !important;
}

.con-modal .ant-modal-content {
  border-radius: 24px !important;
  padding: 0px 24px !important;
}

.con-modal .view-supplier-hr {
  border-top: 0.1rem solid var(--color-solid-lightgrey);
}

.no-table-text {
  font-weight: 600;
  color: var(--color-solid-darkgrey);
}

.auto-fill-div {
  padding: 4px 5px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 13px;
  width: 100%;
  font-weight: 100;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 80px !important;
}

.currency-fill-div {
  padding: 4px 5px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 13px;
  font-weight: 100;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px !important;
}

.pp-div {
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  width: 90%;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  background: var(--color-solid-white);
  border-radius: 6px;
  font-weight: 300;
}

.days_initial_div .ant-form-item:first-child {
  margin-bottom: 0px !important;
}

.clear-btn {
  border-radius: 20px;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkgrey);
  border: 1px solid var(--color-solid-darkgrey);
  height: 35px;
  padding: 0px 30px 0px 30px;
}

.clear-btn:hover {
  border: 1px solid var(--color-solid-darkblue);
}

.custom-upload-wrapper .ant-upload {
  width: 100% !important;
}

.notes-and-documents {
  background: white;
  margin: 10px 20px;
  padding: 5px;
  border-radius: 0px 20px 20px 20px;
  overflow: auto;
  height: calc(100vh - 155px);
}

.root-contract-input {
  width: 90%;
  border: 1px solid var(--color-solid-lightgrey);
  padding: 10px;
  border-radius: 10px;
  cursor: not-allowed;
}
