.template-upload {
  height: 35px;
  box-shadow: none !important;
  border: 1px solid var(--color-solid-lightblue);
  border-radius: 20px;
  font-weight: 600;
}

.org-temp-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px 22px;
  height: calc(100vh - 175px);
  overflow: auto;
}

.template-header-names {
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 10px !important;
  width: 100% !important;
  background-color: var(--color-solid-backgroundgrey);
}

.template-setup1 {
  background-color: transparent !important;
  padding: 15px;
  border-radius: 10px;
  color: var(--color-lightshade-black);
  /* border: none !important; */
  border-color: blue;
}

.template-setup2 {
  background-color: var(--color-solid-backgroundgrey);
  margin-top: 10px;
  /* height: 25%; */
  border-radius: 10px;
  margin-left: 10px;
  overflow: scroll;
  padding: 20px;
  line-height: 30px;
}

.text-item {
  color: var(--color-lightshade-black);
  margin-left: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  align-content: space-between;
}

.main-test .contract_authority-upload-wrapper .ant-upload {
  width: 100% !important;
}

.checkbox-styles .ant-checkbox-group {
  display: block !important;
  width: 100% !important;
  height: 250px;
  overflow: scroll;
}

.checkbox-styles .ant-checkbox-group .ant-checkbox-wrapper {
  display: flex !important;
  width: 100% !important;
  margin-inline-start: 0;
  padding: 10px;
}

.header-div {
  background-color: var(--color-solid-backgroundgrey);
  padding: 8px 0px 8px 10px;
  border-radius: 10px;
  color: var(--color-lightshade-black);
}

.check-header > :not(:first-child) {
  margin-top: 16px;
}

.content-radio {
  display: flex;
  flex-direction: column;
}

.content-radio .content-radio-wrapper {
  margin-top: 5px;
  border: 2px solid var(--color-border);
  border-radius: 7px;
  padding: 7px;
}

.template-header {
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 10px !important;
  width: 100% !important;
  background-color: var(--color-solid-backgroundgrey);
  padding: 10px;
}

.templateview-input {
  background-color: var(--color-solid-white) !important;
  color: var(--color-solid-black) !important;
}

.templateSetup-1 {
  background-color: var(--color-solid-backgroundgrey);
  height: 25%;
  border-radius: 10px;
  margin-left: 10px;
  overflow: scroll;
  padding: 25px;
  line-height: 30px;
}

.templateSetup-2 {
  background-color: var(--color-solid-backgroundgrey);
  padding: 5px;
  border-radius: 10px;
  color: var(--color-lightshade-black);
  border: none !important;
}

.preview-div {
  border: 0.1rem solid var(--color-border-grey) !important;
  background-color: var(--color-solid-white);
  padding: 4rem 4.5rem;
  min-height: calc(100vh - 150px);
}

.template-head {
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.empty-p {
  height: 2rem;
}

.create-Preview-div {
  width: 100%;
}

.system-setup1 .create-Preview-div {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 5px 20px;
  overflow-y: auto;
  height: calc(100vh - 130px);
}
