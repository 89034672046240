/* #header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  right: 0;
  z-index: 1000;
} */

.App-header {
  position: relative;
}

.ant-dropdown-menu-item-disabled {
  padding: 0px 12px 0px 12px !important;
  cursor: default !important;
}

.logout-btn {
  width: 100%;
  background-color: var(--color-solid-red);
  color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-red);
}

.logout-btn:hover {
  color: var(--color-solid-white) !important;
  border: 1px solid var(--color-solid-red) !important;
}

.header-row-new {
  border-bottom: 2px solid var(--color-solid-white);
  padding: 0px 10px 0px 10px;
  height: 60px;
  background-color: var(--color-solid-backgroundblue);
}

.sub-menu-bg-extra {
  position: absolute;
  top: 13%;
  right: 0;
  left: 20%;
  min-width: max-content;
  z-index: 1;
  transition: left 0.5s ease, right 0.5s ease, opacity 0.4s ease;
  opacity: 1;
}

.sub-menu-bg-extra.hidden {
  opacity: 0;
}

.sub-menu-bg-extra.left-slide {
  animation: slideToLeftExtra 0.5s ease forwards;
}

.sub-menu-bg-extra.right-slide {
  animation: slideToRightExtra 0.5s ease forwards;
}

@keyframes slideToLeftExtra {
  from {
    left: 10%;
  }
  to {
    left: 30%;
  }
}

@keyframes slideToRightExtra {
  from {
    left: 30%;
  }
  to {
    left: 100%;
  }
}

.sub-menu-bg {
  position: absolute;
  top: 13%;
  right: 0;
  width: 100%;
  min-width: max-content;
  z-index: 1;
  transition: left 0.5s ease, right 0.5s ease, opacity 0.4s ease;
  opacity: 1;
}

.sub-menu-bg.hidden {
  opacity: 0;
}

.sub-menu-bg.left-slide {
  animation: slideToLeft 0.5s ease forwards;
}

.sub-menu-bg.right-slide {
  animation: slideToRight 0.5s ease forwards;
}

.sub-menu-bg-contract,
.sub-menu-bg-con-auth,
.sub-menu-bg-commercial,
.sub-menu-bg-act-man,
.sub-menu-bg-sys-set,
.sub-menu-bg-reports,
.sub-menu-bg-user-man {
  position: absolute;
  top: 13%;
  bottom: 0;
  right: 0;
  width: 100%;
  min-width: max-content;
  z-index: 1;
  transition: left 0.5s ease, right 0.5s ease, opacity 0.4s ease;
  opacity: 1;
}

.sub-menu-lite-bg-contract,
.sub-menu-bg-con-auth,
.sub-menu-bg-commercial,
.sub-menu-bg-act-man,
.sub-menu-bg-sys-set,
.sub-menu-bg-reports,
.sub-menu-bg-user-man {
  position: absolute;
  top: 13%;
  bottom: 0;
  right: 0;
  width: 100%;
  min-width: max-content;
  z-index: 1;
  transition: left 0.5s ease, right 0.5s ease, opacity 0.4s ease;
  opacity: 1;
}

.sub-menu-bg-contract.hidden,
.sub-menu-bg-con-auth.hidden,
.sub-menu-bg-commercial.hidden,
.sub-menu-bg-act-man.hidden,
.sub-menu-bg-sys-set.hidden,
.sub-menu-bg-reports.hidden,
.sub-menu-bg-user-man.hidden {
  opacity: 0;
}

.sub-menu-lite-bg-contract.hidden,
.sub-menu-bg-con-auth.hidden,
.sub-menu-bg-commercial.hidden,
.sub-menu-bg-act-man.hidden,
.sub-menu-bg-sys-set.hidden,
.sub-menu-bg-reports.hidden,
.sub-menu-bg-user-man.hidden {
  opacity: 0;
}

.sub-menu-bg-contract.left-slide {
  animation: slideToLeftContract 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-lite-bg-contract.left-slide {
  animation: slideToLeftContract 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-contract.right-slide {
  animation: slideToRightContract 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-lite-bg-contract.right-slide {
  animation: slideToRightContract 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-con-auth.left-slide {
  animation: slideToLeftConAuth 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-con-auth.right-slide {
  animation: slideToRightConAuth 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-commercial.left-slide {
  animation: slideToLeftCommercial 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-commercial.right-slide {
  animation: slideToRightCommercial 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-act-man.left-slide {
  animation: slideToLeftActMan 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-act-man.right-slide {
  animation: slideToRightActMan 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-sys-set.left-slide {
  animation: slideToLeftSysSet 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-sys-set.right-slide {
  animation: slideToRightSysSet 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-reports.left-slide {
  animation: slideToLeftReports 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-reports.right-slide {
  animation: slideToRightReports 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-user-man.left-slide {
  animation: slideToLeftUserMan 0.5s ease forwards;
  transform: translateX(30px);
}

.sub-menu-bg-user-man.right-slide {
  animation: slideToRightUserMan 0.5s ease forwards;
  transform: translateX(30px);
}

@media (min-width: 641px) and (max-width: 1007px) {
  .sub-menu-bg-contract.left-slide,
  .sub-menu-bg-user-man.right-slide,
  .sub-menu-bg-user-man.left-slide,
  .sub-menu-bg-reports.right-slide,
  .sub-menu-bg-reports.left-slide,
  .sub-menu-bg-sys-set.right-slide,
  .sub-menu-bg-sys-set.left-slide,
  .sub-menu-bg-act-man.right-slide,
  .sub-menu-bg-act-man.left-slide,
  .sub-menu-bg-commercial.right-slide,
  .sub-menu-bg-commercial.left-slide,
  .sub-menu-bg-con-auth.right-slide,
  .sub-menu-bg-con-auth.left-slide,
  .sub-menu-lite-bg-contract.right-slide,
  .sub-menu-bg-contract.right-slide,
  .sub-menu-lite-bg-contract.left-slide {
    transform: translateX(10px);
  }
  .header-menu-items > div:first-of-type {
    margin-right: 20px;
  }
}

@keyframes slideToLeft {
  from {
    left: 0;
  }
  to {
    left: 10%;
  }
}

@keyframes slideToRight {
  from {
    left: 10%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftContract {
  from {
    left: 0;
  }
  to {
    left: 11.5%;
  }
}

@keyframes slideToRightContract {
  from {
    left: 11.5%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftConAuth {
  from {
    left: 0;
  }
  to {
    left: 15.3%;
  }
}

@keyframes slideToRightConAuth {
  from {
    left: 15.3%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftCommercial {
  from {
    left: 0;
  }
  to {
    left: 12.5%;
  }
}

@keyframes slideToRightCommercial {
  from {
    left: 12.5%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftActMan {
  from {
    left: 0;
  }
  to {
    left: 15.8%;
  }
}

@keyframes slideToRightActMan {
  from {
    left: 15.8%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftSysSet {
  from {
    left: 0;
  }
  to {
    left: 13.3%;
  }
}

@keyframes slideToRightSysSet {
  from {
    left: 13.3%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftReports {
  from {
    left: 0;
  }
  to {
    left: 10.8%;
  }
}

@keyframes slideToRightReports {
  from {
    left: 10.8%;
  }
  to {
    left: 100%;
  }
}

@keyframes slideToLeftUserMan {
  from {
    left: 0;
  }
  to {
    left: 15.3%;
  }
}

@keyframes slideToRightUserMan {
  from {
    left: 15.3%;
  }
  to {
    left: 100%;
  }
}

.sub-menu-bg-contract.ant-image-img,
.sub-menu-bg-con-auth .ant-image-img,
.sub-menu-bg-commercial .ant-image-img,
.sub-menu-bg-act-man .ant-image-img,
.sub-menu-bg-sys-set .ant-image-img,
.sub-menu-bg-reports .ant-image-img,
.sub-menu-bg-user-man .ant-image-img {
  width: 80%;
}

.sub-menu-lite-bg-contract .ant-image-img {
  width: 110%;
}

.home-icon {
  cursor: pointer;
  width: 60px;
  height: 50px;
}

.home-lite-icon {
  cursor: pointer;
  width: 80px;
  height: 50px;
}

.header-new {
  display: flex;
}

.header-menu-items {
  display: flex;
  padding-left: 20px !important;
  z-index: 9;
}

.header-menu-items > a {
  display: flex;
}

.header-menu-items > div:first-of-type {
  margin-right: 50px; /* added margin to increase the space*/
}

.menu-border {
  cursor: default;
  margin: 13px 10px 10px 10px;
  padding: 6px 10px;
  white-space: nowrap;
  position: relative;
  /* color: var(--color-solid-darkerblue); */
  color: black; /*changed color to black*/
  font-weight: 600;
}

.menu-lite-contract-border {
  cursor: default;
  margin: 13px 0px 10px 8px;
  padding: 6px 16px;
  white-space: nowrap;
  position: relative;
  color: var(--color-solid-darkerblue);
  font-weight: 600;
}

.menu-lite-border {
  cursor: default;
  margin: 13px 10px 10px 25px;
  padding: 6px 10px;
  white-space: nowrap;
  position: relative;
  color: var(--color-solid-darkerblue);
  font-weight: 600;
}

.menu-lite-border::before {
  content: "";
  position: absolute;
  bottom: 1px;
  height: 3px;
  width: 0;
  border-radius: 10px;
  left: 10%;
  background-color: #0177fb;
  border-radius: 20px;
  animation: expandWidth 0.5s forwards;
}

.menu-lite-contract-border::before {
  content: "";
  position: absolute;
  bottom: 1px;
  height: 3px;
  width: 0;
  border-radius: 10px;
  left: 10%;
  background-color: #0177fb;
  border-radius: 20px;
  animation: expandWidth 0.5s forwards;
}

.menu-border::before {
  content: "";
  position: absolute;
  bottom: 1px;
  height: 3px;
  width: 0;
  border-radius: 10px;
  left: 10%;
  /* background-color: #0177fb; */
  background-color: black;
  border-radius: 20px;
  animation: expandWidth 0.5s forwards;
}

@keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}

.back-menu .menu-back-arrow {
  cursor: pointer;
  padding: 0px 3px;
  margin: 16px 0px 13px 0px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 8px;
  margin-right: 30px;
  width: 20px;
  height: 25px;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.back-menu .menu-back-arrow:hover {
  border: 1px solid var(--color-solid-lightblue);
  background-color: var(--color-solid-white);
}

.header-right-align {
  display: flex;
  margin-left: auto;
  z-index: 9;
}

.menu-label-home {
  /* color: var(--color-solid-darkerblue); */
  color: var(--color-solid-black);
  white-space: nowrap;
  margin: 13px 10px 11px 10px;
  padding: 6px 10px;
  cursor: pointer;
  z-index: 9;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  transition: border 0.2s ease, color 0.2s ease, background-color 0.2s ease;
}

.menu-lite-label-home {
  /* color: var(--color-solid-darkerblue); */
  color: var(--color-solid-black);
  white-space: nowrap;
  margin: 13px 10px 11px 40px;
  padding: 6px 10px;
  cursor: pointer;
  z-index: 9;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  transition: border 0.2s ease, color 0.2s ease, background-color 0.2s ease;
}

.menu-label-home:hover {
  /* color: var(--color-solid-white); */
  /* background-color: var(--color-solid-darkblue); */
  text-decoration: underline;
}

.menu-lite-label-home:hover {
  /* color: var(--color-solid-white);
  background-color: var(--color-solid-darkblue); */ /* text color change in home menu */
  text-decoration: underline;
}

.menu-label-back-home {
  color: var(--color-solid-darkergrey);
  white-space: nowrap;
  margin: 13px 10px 11px 10px;
  padding: 6px 10px;
  cursor: pointer;
  z-index: 9;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  transition: border 0.2s ease, color 0.2s ease, background-color 0.2s ease;
}

.menu-label-back-home:hover {
  color: var(--color-solid-white);
  background-color: var(--color-solid-darkblue);
}

.menu-label {
  color: var(--color-solid-darkergrey);
  font-weight: 600;
  white-space: nowrap;
  margin: 13px 10px 11px 10px;
  padding: 6px 8px;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  z-index: 9;
  transition: border 0.5s ease, color 0.3s ease;
}

.menu-label:hover {
  border: 1px solid var(--color-solid-backgroundgrey);
  color: var(--color-solid-black);
}

.menu-label-back {
  color: var(--color-solid-darkerblue);
  white-space: nowrap;
  margin: 13px 10px 11px 10px;
  padding: 6px 10px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.menu-label-back:hover {
  color: var(--color-solid-white);
  background-color: var(--color-solid-darkblue);
}

.menu-label-back:before {
  content: "";
  position: absolute;
  bottom: 3px;
  height: 3px;
  width: 0;
  border-radius: 10px;
  background-color: #0177fb;
  transition: background-color 0.2s ease;
  animation: shrinkWidthBack 0.2s forwards;
}

@keyframes shrinkWidthBack {
  from {
    width: 80%;
    left: 0;
  }
  to {
    width: 20px;
    left: 42%;
  }
}

.menu-label-back:hover:before {
  background-color: white;
}

.menu-label-active {
  z-index: 9;
  /* color: var(--color-solid-darkestblue); */ /* Removed this line to change the selected text color */
  color: black;
  font-weight: bold;
  white-space: nowrap;
  margin: 13px 10px 10px 10px;
  padding: 6px 8px;
  /* border: 1px solid transparent; */
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: border 0.5s ease;
  border-radius: 20px;
}

.menu-label-active:hover {
  /* border: 1px solid #b0e0e6; */
}

.menu-label-active::before {
  content: "";
  position: absolute;
  bottom: 2px;
  height: 3px;
  width: 0;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: var(--color-solid-darkestblue); */
  background-color: black;
  animation: growWidthFromCenter 0.3s forwards;
}

@keyframes growWidthFromCenter {
  from {
    width: 0;
  }
  to {
    width: 20px;
  }
}

.booster-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booster-align-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booster-icon {
  padding-bottom: 2px;
}

#header .button-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .notificationIcon-new {
  border-radius: 100%;
  cursor: pointer;
  font-size: 20px;
}

#header .notify-icon {
  color: #9b9b9b;
  transition: color 0.2s ease;
}

#header .notify-icon:hover {
  color: var(--color-solid-darkerblue);
}

#header .notify-icon > svg {
  width: 25px;
  height: 25px;
}

#header .ant-badge .ant-badge-count {
  background: #0177fb !important;
  font-size: 10px;
}

.ant-notification .ant-notification-notice-wrapper {
  border-radius: 15px !important;
  cursor: pointer;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  border-radius: 100%;
}

.push-notification .push-notification-title {
  color: #0177fb;
  font-size: 13px;
  font-weight: 600;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 2px;
}

.push-notification .push-notification-action-icon {
  color: var(--color-solid-darkgrey);
  padding: 4px;
}

.push-notification .push-notification-action-icon:hover {
  color: var(--color-solid-black);
  background-color: #f0f0f0;
  padding: 4px;
  border-radius: 100%;
}

.push-notification .ant-notification-notice-description {
  margin: 0px !important;
}

.push-notification
  .ant-notification-notice-description
  .push-notification-divider {
  width: 100%;
  margin: 10px 0px;
  height: 2px;
  color: #e8e8e8;
  background-color: #e8e8e8;
}

#notification-center .ant-drawer-title {
  color: #0177fb;
}

#notification-center .ant-drawer-body {
  overflow-x: hidden;
  background-color: var(--color-solid-backgroundgrey);
  padding-bottom: 50px;
}

#notification-center .notification-read {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
  padding: 10px 20px 15px 20px !important;
  margin-bottom: 20px !important;
  width: 350px !important;
  background-color: #eaeaea;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

#notification-center .notification-read:hover {
  background-color: #eeeeee;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

#notification-center .notification-read .notification-action-icon {
  border: 2px solid transparent;
  border-radius: 100%;
  transition: background-color 0.2s ease, border 0.2s ease;
}

#notification-center .notification-read:hover .notification-action-icon {
  border: 2px solid transparent;
  border-radius: 100%;
  transition: background-color 0.2s ease, border 0.2s ease;
}

#notification-center .notification-read:hover .notification-action-icon:hover {
  border: 2px solid #fff;
  border-radius: 100%;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkgrey);
}

#notification-center .notification-read:hover .notification-expand-toggle {
  transition: background-color 0.2s ease;
}

#notification-center
  .notification-read:hover
  .notification-expand-toggle:hover {
  background-color: #fff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
}

#notification-center .notification {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
  padding: 10px 20px 15px 20px !important;
  margin-bottom: 20px !important;
  width: 350px !important;
  background-color: var(--color-solid-white);
  transition: background-color 0.3s ease;
}

#notification-center .notification:hover {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #0177fb;
  cursor: pointer;
}

#notification-center .notification-title {
  color: #0177fb;
  font-size: 13px;
  font-weight: 600;
  display: block;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#notification-center .notification-content {
  color: var(--color-solid-black);
  font-size: 13px;
  line-height: 175%;
}

#notification-center .notification:hover .notification-title,
#notification-center .notification:hover .notification-content {
  color: #fff;
}

#notification-center .notification-divider {
  margin: 10px 0px;
  height: 2px;
  color: #e8e8e8;
  background-color: #e8e8e8;
}

#notification-center .notification-action-icon {
  cursor: pointer;
  color: var(--color-solid-darkgrey);
  border: 2px solid transparent;
}

#notification-center .notification .notification-action-icon {
  border: 2px solid transparent;
  border-radius: 100%;
  transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
}

#notification-center .notification:hover .notification-action-icon {
  color: var(--color-solid-white);
  border: 2px solid transparent;
  border-radius: 100%;
  transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
}

#notification-center .notification:hover .notification-action-icon:hover {
  border: 2px solid #fff;
  border-radius: 100%;
  background-color: var(--color-solid-white);
  color: #0177fb;
}

#notification-center .notification-time {
  font-size: 10px;
  font-weight: 600;
  color: #0177fb;
}

#notification-center .notification:hover .notification-time {
  color: #fff;
}

#notification-center .notification-expand-toggle {
  font-size: 10px;
  font-weight: 600;
  color: #0177fb;
  border-radius: 10px;
  padding: 5px;
}

#notification-center .notification:hover .notification-expand-toggle {
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#notification-center .notification:hover .notification-expand-toggle:hover {
  color: #0177fb;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
}

#notification-center .mark-read-btn {
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  height: 35px;
}

#notification-center .mark-read-btn:disabled {
  cursor: default !important;
}

#notification-center .ant-drawer-body .clear-notify-align {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#notification-center .clear-notify-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.1),
    0 0 12px rgba(0, 0, 0, 0.05);
}

.menu-arrow {
  transition: 0.2s ease;
}

.rotate180 {
  /* transform: rotate(-180deg); */
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.search-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchbar {
  color: red;
}

/* .all-search {
  padding: 6px 10px;
  width: 36px;
  /* height: 35px; */
/* border-radius: 20px; */
/* background-color: #0177fb !important; */
/* border: 1px solid var(--color-solid-blue); */
/* border: none; */
/* cursor: pointer; */
/* transition: width 0.4s; */
/* transition-timing-function: ease-in-out; */
/* } */

/* .all-search .ant-input,
.all-search .ant-input:hover,
.all-search .ant-input-outlined:hover {
  background-color: #0177fb !important;
  color: #fff !important;
} */

.all-search-clicked {
  padding: 6px;
  width: 220px;
  height: 35px;
  border-radius: 20px;
  /* background-color: #0177fb !important; */
  background-color: #f0f4f8 !important;

  border: 2px solid gray;
}

.all-search-clicked .ant-input {
  background-color: #f0f4f8 !important;
  color: #000000 !important;
}

.all-search-clicked .ant-input::placeholder {
  color: var(--color-solid-darkgrey) !important;
}

.enmatter-align {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

#header {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  /* For Judo Background */
  /* box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4); */
  /* border-radius: 0 0 15px 15px; */
  overflow: hidden;
}

#header .header-row {
  padding: 20px 30px 0 30px;
}

#header .logo-text {
  color: var(--color-solid-black);
  font-size: 21px;
  text-align: left;
  padding: 0 0 0 12px;
}

.booster {
  background-color: var(--color-solid-white);
  border: 0;
  padding-inline-start: 30px !important;
  padding-inline-end: 30px !important;
  font-size: 14px !important;
}

.ant-badge-dot {
  width: 12px !important;
  height: 12px !important;
  top: 3px !important;
  right: 3px !important;
  /* box-shadow: none !important; */
}

#header .ant-btn {
  height: 35px !important;
  padding-inline-start: 25px !important;
  padding-inline-end: 25px !important;
}

#header .ant-input-search .ant-btn,
#header .ant-input-search .ant-btn:focus {
  height: auto !important;
  background: transparent;
  color: var(--color-solid-white);
  border: 0 !important;
  box-shadow: none !important;
}

/* #header .ant-menu {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.6);
  line-height: 40px;
}

#header .ant-menu-item-selected,
#header li {
  background: transparent;
  border-bottom: 0;
}

#header .ant-menu-item-selected:after {
  border-bottom: 0;
}

#header .ant-menu-item-selected:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 30%;
  margin: 0 35%;
  border-radius: 5px;
  border-bottom: 3px solid var(--color-solid-darkblue);
}

#header .ant-menu-item:after {
  border: 0;
}

#header .ant-menu-item {
  border-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-solid-white);
  padding-inline: 39px !important;
} */

/* #header .header-menu {
  position: fixed;
  top: 70px;
  left: 60px;
  width: auto;
} */

#header .dash-welcome-holder {
  padding-left: 50px;
  font-size: 22px;
  font-weight: 600;
}

#header .dash-welcome-holder .dash-welcome-user {
  color: #0177fb;
}

#header .dash-last-login-holder {
  padding-right: 50px;
  font-size: 13px;
  font-weight: 600;
}

#header .dash-last-login-holder .dash-last-login-date {
  color: #0177fb;
}

#header .search-section {
  /* For Judo Background */
  background: var(--color-solid-backgroundgrey);
}

#header .header-search {
  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  /* background-color: rgba(255, 255, 255, 0.2); */
  background-color: var(--color-solid-white);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
}

/* #header .search-icon {
  cursor: pointer;
  width: 30px;
} */

#header .search-suffix {
  display: flex;
  color: var(--color-solid-darkergrey);
}

#header .arrow-icon {
  padding-left: 5px;
  cursor: pointer;
  width: 20px;
}

#header .header-search .ant-input,
#header .header-search .ant-input:focus {
  background: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
  /* color: var(--color-solid-white); */
}

#header .search-input .ant-input::placeholder {
  color: var(--color-solid-darkergrey);
}

/* #header .header-search .ant-input::placeholder:before {
  animation: animate;
  animation-duration: 7s;
  animation-iteration-count: infinite;
} */

#header .search-back-column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

#header .searchBack {
  color: var(--color-solid-darkgrey);
  font-size: 15px;
  background-color: var(--color-solid-white);
  border-radius: 20px;
  margin-top: 35px;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}

#header .search-input {
  padding-bottom: 10px;
}

/* Feed Text Animation */
#header .header-feed-text {
  padding: 1px 5px;
  /* padding: 4px 5px; */
  color: var(--color-solid-black);
  background-color: var(--color-solid-white);
  border-radius: 10px;
  /* font-size: 10px; */
  font-size: 9px;
  text-align: center;
}

/* #header .header-feed-text::after {

    content:"";
  display:inline-block;
  animation: animate1;
  animation-duration: 7s;
  animation-iteration-count: infinite;
} */

@keyframes animate {
  0% {
    content: "";
    padding: 0px 5px;
  }

  50% {
    content: "Geeks";
    padding: 5px 10px;
  }

  75% {
    content: "Geeks";
    padding: 5px 10px;
  }
}

@keyframes animate1 {
  0% {
    content: "";
    width: 0%;
  }

  20% {
    content: "Geeks";
    width: 20%;
  }

  40% {
    content: "";
    width: 0%;
  }

  60% {
    content: "Geeks";
    width: 20%;
  }

  80% {
    content: "";
    width: 0%;
  }

  100% {
    content: "Geeks";
    width: 20%;
  }
}

/* Notification Icon css */

#header .notificationIcon {
  background: var(--color-solid-white);
  margin: auto;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  /* top: 4px; */
  top: -15px;
  left: 10px;
}

#header .notificationIcon .anticon {
  color: var(--color-solid-white);
  animation: colorChange 2s infinite;
  font-size: 20px;
}

@keyframes colorChange {
  0% {
    color: var(--color-solid-green);
  }

  50% {
    color: var(--color-solid-white);
  }

  100% {
    color: var(--color-solid-green);
  }
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes breathe2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

#header .notificationIcon:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -3px;
  z-index: 2;
  width: 40px;
  height: 40px;
  /* background:#2033db; */
  border: 2px solid var(--color-solid-white);
  opacity: 0.3;
  border-radius: 100%;
  animation: breathe2 2s infinite;
}

#header .notificationIcon:after {
  content: "";
  position: absolute;
  top: -20px;
  left: -18px;
  z-index: 3;
  width: 70px;
  height: 70px;
  /* background:#2033db; */
  border: 2px solid var(--color-solid-white);
  opacity: 0.2;
  border-radius: 100%;
  animation: breathe 2s infinite;
}

#header .notificationIcon > span {
  color: var(--color-solid-white);
  font-weight: bold;
  font-size: 25px;
  position: absolute;
  z-index: 4;
  top: 0px;
  left: 8px;
}

/* Radio Buttons */

#header .radio-group {
  width: 100%;
  margin: 20px 10px 0 10px;
}

#header .radio-group span {
  color: var(--color-solid-white);
  font-size: 15px;
}

#header .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 3px solid var(--color-solid-darkgrey);
}

#header .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--color-solid-lightblue) !important;
  border: 3px solid var(--color-solid-purple) !important;
}

#header .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-solid-lightblue) !important;
}

/* pinned document area */

#header .docBar {
  background-color: rgba(0, 0, 0, 0.5);
}

#header .pinDoc {
  width: 40px;
  height: 40px;
  background-color: var(--color-solid-lightgrey);
  border-radius: 8px;
  margin: 5px 25px;
}

#header .theme-icon,
#header .edit-icon {
  margin-right: 30px;
  width: 15px;
  cursor: pointer;
}

#header .search-row {
  margin-top: 50px;
}

.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: transparent;
  margin-bottom: 30px;
}

.ant-dropdown:has(> .logout-menu) {
  z-index: 9999 !important;
}
