.fy-cur-div {
  border: 1px solid var(--color-solid-darkblue);
  background: var(--color-solid-darkblue);
  color: var(--color-solid-white);
  font-weight: 600;
  text-align: center;
  border-radius: 13px;
  padding: 6px;
}

.currency-div {
  border: 1px solid var(--color-solid-darkblue);
  font-weight: 600;
  text-align: center;
  border-radius: 13px;
  padding: 6px;
}

.year-select-col {
  border: 1px solid var(--color-solid-darkblue) !important;
  border-radius: 13px !important;
  padding: 2px !important;
}

.year-select {
  width: 100%;
}

.aggregate-table {
  padding: 0 20px;
}

.table-switch-btn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-solid-darkerblue) !important;
  height: 25px;
  width: 30px !important;
}
