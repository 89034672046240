#create-contract-layout {
  padding: 25px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--color-solid-backgroundgrey) !important;
}

#create-contract-card .ant-card-body {
  padding: 25px 15px 5px 15px !important;
}

.create-contract-title-card {
  box-shadow: 5px 5px 20px transparent !important;
  border: 2px solid white !important;
  transition: all 0.2s ease !important;
  color: var(--color-solid-darkergrey) !important;
}

.create-contract-title-card:hover {
  border: 2px solid var(--color-solid-darkestblue) !important;
}

.create-contract-title-card.selected {
  border: 2px solid var(--color-solid-darkestblue) !important;
  color: var(--color-solid-darkerblue) !important;
}

.create-contract-tree-card .ant-card-body,
.create-contract-tree-info-card .ant-card-body {
  padding: 10px !important;
}

.create-contract-tree-info-card {
  height: calc(100vh - 298px) !important;
}

#create-contract-breadcrumb-card {
  background-color: #ffffff4f !important;
  box-shadow: none !important;
}

#create-contract-breadcrumb-card .ant-card-body {
  padding: 8px 20px !important;
}

.create-contract-divider {
  height: 20px !important;
  width: 2px !important;
  /* margin-left: -8px !important; */
  border-inline-start: 2px solid rgba(5, 5, 5, 0.2);
}

.create-contract-tree-wrapper {
  padding: 6px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  width: 100%;
}

/* .create-contract-tree-wrapper {
  height: calc(100vh - 358px);
} */

.create-contract-tree .ant-tree-treenode {
  padding: 0px 5px 5px 0px !important;
}

.create-contract-tree .ant-tree-switcher {
  margin-inline-end: 0px !important;
}

.create-contract-tree .ant-tree-switcher::before {
  height: 34px !important;
}

.create-contract-tree .ant-tree-switcher-icon {
  margin-top: 13px !important;
}

.tree-node-container {
  display: flex;
  position: relative;
}

.tree-node-container .tree-node-connector::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -3px;
  width: 0.1rem;
  height: 15px;
  border-radius: 10px;
  background-color: #e6e6e6;
}

.tree-node-container .tree-node-connector::after {
  content: "";
  position: absolute;
  top: 16px;
  left: -7px;
  width: 17px;
  height: 0.15rem;
  border-radius: 10px;
  background-color: #e6e6e6;
}

.tree-node-container.root-node {
  padding-left: 15px !important;
}

.create-contract-tree-represent-wrapper {
  padding: 6px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  width: 100%;
  /* border: 1px solid #cfcfcf; */
  padding-top: 5px;
}

.create-contract-tree-represent-wrapper {
  height: calc(100vh - 396px);
  /* margin-top: 20px; */
}

.create-contract-tree-represent .ant-tree-treenode {
  padding: 0px 0px 0px 0px !important;
}

.create-contract-tree-represent .ant-tree-switcher {
  margin-inline-end: 0px !important;
  display: none !important;
}

.create-contract-tree-represent .ant-tree-switcher::before {
  display: none !important;
}

.create-contract-tree-represent .ant-tree-switcher-icon {
  margin-top: 0 !important;
}

.create-contract-tree-represent-empty .ant-empty-image {
  margin-bottom: 50px !important;
}

.scroll-container {
  height: calc(100vh - 360px);
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.loading-container,
.empty-container {
  height: calc(100vh - 362px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  font-size: 40px;
  color: var(--color-solid-darkerblue);
}

.loading-more {
  margin-top: -8px;
  margin-bottom: 8px;
  padding: 2px 35px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
}

.loading-more-icon {
  font-size: 20px;
  color: var(--color-solid-darkerblue);
  margin-right: 15px;
}

.contract-path-container {
  height: calc(100vh - 363px);
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.contract-path-header {
  height: 31px;
  width: 100%;
  border-bottom: 1px solid #cfcfcf;
  padding: 5px 10px;
}

.contract-path-title {
  font-weight: 600;
  font-size: 13px;
  margin-left: 10px;
}

.tree-represent-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-text {
  font-weight: 600;
  font-size: 13px;
  color: var(--color-solid-darkgrey);
}

.selected-type {
  color: var(--color-solid-darkergrey);
}
