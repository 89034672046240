.fc .fc-toolbar-title{
    font-size: 15px !important;
}

.fc-scroller{
    overflow: hidden !important;
}

.fc-button{
    background-color: transparent !important;
    color: var(--color-solid-black) !important;
    border: 0 !important;
    box-shadow: none !important;
}

.fc-scrollgrid th, .fc-scrollgrid td, .fc-scrollgrid{
    border: 0 !important;
    text-align: center !important;
}

.fc-scrollgrid th a{
    color: var(--color-solid-darkgrey);
    font-weight: 400;
}

.fc-daygrid-day-top {
    text-align: center !important;
    display: block !important;
    padding: 15px;
}

.fc-daygrid-day-top a{
    color: var(--color-solid-black);
    font-weight: 500;
    font-size: 13px;
}

.fc-daygrid-day-events{
    display: none !important;
}

.fc .selectedDates a {
    color: var(--color-solid-white) !important;
    background-color: var(--color-solid-darkblue) !important;
    padding: 7px 10px;
    border-radius: 100%;
    font-weight: 300 !important;
}

.fc .fc-highlight{
    background-color: transparent !important;
}





  #dashboard .card-type4-sub {
    background: var(--color-solid-white);
    cursor: pointer;
  }

  #dashboard .card-type4-sub:hover{
    background: var(--color-shade-yellow);
  }
  
  #dashboard .card-type4-sub .ant-card-body {
    border-radius: 12px !important;
    padding: 10px !important;
  }
  
  #dashboard .card-type4-sub span {
    color: var(--color-solid-black);
    font-size: 12px;
    font-weight: 500;
    padding: 0 10px;
   
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  #dashboard .card-type4-sub span a {
    color: var(--color-solid-darkgrey);
    text-align: right;
    font-size: 10px;
  }
  #dashboard .card-type4-sub .cir-card{
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  #dashboard .card-type4-sub:hover  .cir-card{
    background: var(--color-solid-white);
  }
  #dashboard .card-type4-sub .cir-card1{
    background: var(--color-gradient-blue);
  }
  #dashboard .card-type4-sub .cir-card2{
    background: var(--color-gradient-red);
  }
  #dashboard .card-type4-sub .cir-card3{
    background: var(--color-gradient-orange);
  }