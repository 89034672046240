.seed-layout-wrapper {
  margin: 50px 25px 10px 25px;
}

.seed-divider {
  margin: 0 !important;
}

.seed-field {
  background-color: var(--color-solid-white);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.seed-title {
  background-color: var(--color-solid-white);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-solid-darkerblue);
}

.seed-input-number {
  padding: 0 !important;
  font-size: 13px !important;
  height: 34px !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.seed-input-number .ant-input-number-input {
  height: 34px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: var(--color-solid-darkergrey) !important;
}

.seed-input-number .ant-input-number-input:disabled {
  height: 34px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  background-color: #ffffff !important;
  cursor: default !important;
  border-radius: 10px !important;
  color: var(--color-solid-darkergrey) !important;
}

.seed-input-number .ant-input-number-handler-wrap {
  opacity: 1 !important;
  border-radius: 10px !important;
}

.seed-input-number .ant-input-number-handler-up {
  border-start-end-radius: 10px !important;
}

.seed-input-number .ant-input-number-handler-down {
  border-end-end-radius: 10px !important;
}

.seed-input-number .ant-input-number-handler-up:hover,
.seed-input-number .ant-input-number-handler-down:hover {
  background-color: #007bff !important;
  height: 40% !important;
}

.seed-input-number .ant-input-number-handler-up:hover > span,
.seed-input-number .ant-input-number-handler-down:hover > span {
  color: #fff !important;
}

.seed-info-wrapper {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}
.seed-info-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
  margin-left: 8px;
}

.nice-text {
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
}

.perf-button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  height: 35px;
}
