.breadcrumb-title,
.breadcrumb-title-inactive {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 8px;
  transition: all 0.2s ease, color 0.2s ease;
  user-select: none;
}

.breadcrumb-title {
  color: var(--color-solid-darkergrey);
}

.breadcrumb-title-inactive {
  color: var(--color-solid-darkgrey);
}

.breadcrumb-title:hover,
.breadcrumb-title-inactive:hover {
  background-color: #f3f3f3;
  color: var(--color-solid-darkerblue);
  border-radius: 5px;
}

.global-breadcrumb-static,
.global-breadcrumb-navigable,
.global-breadcrumb-home {
  font-size: 13px;
  font-weight: 600;
}

.global-breadcrumb-home:hover {
  cursor: pointer;
  color: var(--color-solid-darkerblue);
  transition: color 0.3s ease;
}

.global-breadcrumb-static:hover {
  cursor: default;
}

.global-breadcrumb-navigable:hover {
  cursor: pointer;
  color: var(--color-solid-darkerblue);
  transition: color 0.3s ease;
}
