.global-individual-filter .ant-checkbox-group {
  overflow: auto;
  height: 200px;
}

.filter-popup-inner .ant-popover-inner {
  min-width: 500px;
  padding: 15px;
  border-radius: 20px;
}

.filter-popup-saved-inner .ant-popover-inner {
  max-width: 250px;
  padding: 15px;
  border-radius: 20px;
}

.global-filter-tab .ant-tabs-nav {
  margin-bottom: 6px;
  width: 100% !important;
  border: 1px solid var(--color-solid-lightgrey);
  border-radius: 20px;
  padding: 1px 1px 2px 2px;
}

.global-filter-tab .ant-tabs-nav::before {
  display: none;
}

.global-filter-tab .ant-tabs-nav .ant-tabs-tab-active::before,
.global-filter-tab .ant-tabs-nav .ant-tabs-tab-active::after {
  display: none !important;
}

.global-filter-tab .ant-tabs-nav-wrap {
  padding: 0 !important;
}

.global-filter-tab .ant-tabs .ant-tabs-tab-active {
  border: none !important;
}

.global-filter-tab .ant-tabs-nav .ant-tabs-tab-active {
  background: none !important;
  border: none !important;
  transition: none !important;
}

.global-filter-tab .ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
  padding: 0;
}

.global-filter-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px 0px 0px 5px !important;
}

.global-filter-tab .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.global-filter-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 6px 20px !important;
  color: var(--color-solid-darkergrey) !important;
}

.global-filter-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
}

.global-filter-tab .ant-tabs-ink-bar {
  display: none !important;
}

.global-filter-tab-inner .ant-tabs-nav {
  margin-bottom: 10px;
  padding-left: 10px;
}

.global-filter-tab-inner .ant-tabs-tab {
  padding: 6px 0 !important;
}

.global-filter-list .ant-list-item {
  border-block-end: none !important;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--color-solid-darkergrey);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.global-filter-list .ant-list-item:hover {
  background-color: #f3f3f3;
  /* background-color: blueviolet; */
}

.global-inner-filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
  padding: 5px;
}

.global-filter-checkbox {
  display: block;
  padding-top: 10px;
}

.global-filter-checkbox .ant-checkbox-wrapper {
  padding: 7px 5px;
  border-radius: 5px;
  color: var(--color-solid-darkergrey);
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.global-filter-checkbox .ant-checkbox-wrapper:hover {
  background-color: #f3f3f3;
}

.global-filter-checkbox .ant-checkbox-wrapper > span {
  text-overflow: ellipsis !important;
  display: inline-block !important;
  white-space: nowrap !important;
  max-width: 294px !important;
}

.filter-popup-inner .ant-popover-inner .ant-picker-range {
  min-width: 317px;
  margin-top: 8px;
}

#saved-filter-list {
  overflow: auto;
  height: 200px;
}

/*     mznckladhafhKFhgshdofgkjihsdgvacfghdj*/
.filter-modal {
  width: 500px;
  padding: 15px;
  border-radius: 20px;
}

.filter-modal .ant-modal-content {
  padding: 15px;
  border-radius: 20px;
}

.filter-modal .ant-modal-header {
  padding: 15px;
  border-radius: 20px;
}

.filter-modal .ant-modal-footer {
  padding: 15px;
  border-radius: 20px;
}

/* Modal footer buttons spacing */
.modal-footer-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

/* Save modal footer alignment */
.save-modal-footer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 24px;
}

/* Selected filters container */
.selected-filters {
  margin-bottom: 16px;
  min-height: 40px;
}
