.border-right {
  border-right: 3px solid #b0afb2;
  /* height: 20px; */
  /* margin-top: 15px; */
}

.tabs-title {
  display: flex;
  padding: 0px 30px 0px 30px;
}

.tabs-title > img {
  /* padding-right: 10px; */
  width: 20px;
  height: 20px;
}

.tabs-title > p {
  padding-left: 10px;
  font-size: 13px;
}

.user-manage-div .ant-tabs-nav {
  background-color: var(--color-solid-white);
  margin: 0;
}

.user-manage-div .ant-tabs-nav-wrap {
  /* margin-top: 10px; */
  padding-left: 25px;
  background-color: var(--color-solid-white);
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--color-solid-white);
  border: none;
  transition: none;
}

.user-manage-div .ant-tabs .ant-tabs-tab-btn .tabs-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.user-manage-div .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn .tabs-title {
  font-weight: 600;
  color: var(--color-solid-black);
  text-shadow: none;
}

.user-manage-div .ant-tabs .ant-tabs-tab-active {
  background-color: var(--color-solid-backgroundgrey) !important;
  border: none;
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-backgroundgrey);
  border: none;
  /* margin-right: 10px; */
  transition: none;
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab-active:before,
.user-manage-div .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-backgroundgrey);
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-backgroundgrey);
}

.user-manage-div .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0;
}

.user-manage-div .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.roles_css {
  border-bottom: black;
  background: var(--color-solid-backgroundgrey);
  color: blue;
  border-radius: 12px 12px 0px 0px;
}

.settings-lists {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  background: var(--color-solid-white);
}

.settings-lists li {
  text-align: center;
  padding: 10px 20px;
  margin: 0px 24px;
  color: var(--color-solid-black);
  display: flex;
}

.border-right {
  border-right: 2px solid var(--color-solid-backgroundgrey);
}

.switch-css .ant-switch-checked {
  background: var(--color-solid-switchgreen) !important;
}

.switch-css .ant-switch .ant-switch-handle::before {
  background: #ffff !important;
}

/* .switch-css .ant-switch:not(.ant-switch-checked) .ant-switch-handle {
  background-color: red;
} */

.setup-tab-content1 {
  z-index: 1;
  /* margin-top: 9rem; */
  overflow-y: scroll;
  /* height: calc(100vh - 16rem); */
}

.setup-tab-content1::-webkit-scrollbar,
.price-profile-content::-webkit-scrollbar {
  display: none;
}

.users-table .ant-table-column-title {
  color: var(--color-solid-darkblue);
}

.delete-btn,
.delete-btn:hover {
  height: 35px;
  box-shadow: none !important;
  background: var(--color-solid-red) !important;
  border: 1px solid var(--color-solid-red) !important;
  border-radius: 13px;
  color: var(--color-solid-white) !important;
  font-weight: 600;
  width: 100px;
}

.settings-cancel-btn,
.settings-cancel-btn:hover {
  height: 35px;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-darkgrey) !important;
  border-radius: 13px;
  color: var(--color-solid-darkgrey) !important;
  font-weight: 600;
  width: 100px;
}

.custom-row-style {
  background: var(--color-solid-white);
}

/* workflow style */
.content-padding {
  padding: 0px 30px;
}

.settings-header-card .ant-card-body {
  padding: 5px 15px;
}
