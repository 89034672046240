#dashboard {
  /* min-height: 100vh; */
  /* background: var(--color-solid-backgroundgrey); */
  /* For Judo Background
  background-image: url("../images/bg/enmatter-logo.jpg");
  background-position: 50% -30%;
  background-repeat: no-repeat;
  background-size: cover; */
}

#dashboard > section {
  background: transparent;
}

#dashboard .section-wrapper {
  height: calc(100vh - 117px);
  padding-top: 1.7%;
  transition: padding-top 0.5s ease;
  position: relative;
}

#dashboard .section-wrapper-overview {
  height: calc(100vh - 117px);
  padding-top: calc(100vh - 645px);
  transition: padding-top 0.5s ease;
  position: relative;
}

#dashboard .section-wrapper-recent {
  height: calc(100vh - 117px);
  padding-top: calc(100vh - 620px);
  transition: padding-top 0.5s ease;
  position: relative;
}

.dashboard-reload-icon > svg {
  height: 14px;
  width: 12px;
  color: var(--color-solid-darkergrey);
  transition: color 0.3s ease;
}

.dashboard-reload-button:hover .dashboard-reload-icon > svg {
  color: var(--color-solid-white) !important;
}

.dashboard-reload-button {
  width: 20px !important;
  height: 20px !important;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.dashboard-reload-button:hover {
  background-color: #05b384 !important;
}

#dashboard .overview-section {
  background-color: #efefef;
  margin: 0px 25px 5px 25px;
  padding: 20px 25px;
  border-radius: 20px;
  border: 1px solid var(--color-border);
  position: relative;
  opacity: 1;
  height: 175px;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
}

#dashboard .overview-section.hidden {
  height: 350px;
}

@keyframes pulse {
  0%,
  100% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -10px) scale(1.2);
    opacity: 0.5;
  }
}

#dashboard .overview-section .arrow-up {
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  left: 50%;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 100%;
  transition: border 0.3s ease, background 0.3s ease;
  animation: pulse 1.5s infinite;
}

#dashboard .overview-section .arrow-up:hover {
  /* border: 1px solid var(--color-solid-darkergrey); */
  background: var(--color-solid-backgroundgrey);
}

#dashboard .overview-card {
  height: 134px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  /* background: linear-gradient(to bottom right, #00bfff, #3e8ede); */
  background: #fff;
  /* background: linear-gradient(
    135deg,
    rgba(240, 240, 240, 0.8),
    rgba(220, 220, 220, 0.8)
  ); */
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

#dashboard .overview-card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

#dashboard .overview-card .ant-card-body {
  padding: 5px !important;
  width: 100% !important;
}

.flip-container {
  perspective: 1000px;
}

.flip-container,
.front,
.back {
  width: 100%;
  height: 100%;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

.flip-container.flipped .flipper {
  transform: rotateY(180deg);
}

#dashboard .flip-container .flipper .overview-card-text-number,
#dashboard .flip-container .flipper .overview-card-text {
  transition: transform 0.6s ease;
}

#dashboard .flip-container.flipped .flipper .overview-card-text-number,
#dashboard .flip-container.flipped .flipper .overview-card-text {
  transform: rotateY(-180deg);
}

#dashboard .overview-card .overview-card-text-number {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
}

#dashboard .overview-card .overview-card-text {
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkgrey);
}

#dashboard .recent-queue-section {
  background-color: #efefef;
  margin: 0px 25px 5px 25px;
  border-radius: 20px;
  border: 1px solid var(--color-border);
  position: relative;
  opacity: 1;
  height: 350px;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.6s ease;
}

#dashboard .recent-queue-section.hidden {
  opacity: 0;
  height: 0;
}

#dashboard .recent-queue-section .arrow-down {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 100%;
  transition: border 0.3s ease, background 0.3s ease;
}

#dashboard .recent-queue-section .arrow-down:hover {
  border: 1px solid var(--color-solid-darkergrey);
  background: var(--color-solid-backgroundgrey);
}

#dashboard .button-wrapper {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
}

#dashboard .section-reset-btn {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.active-supplier-modal {
  backdrop-filter: blur(30px) brightness(100%) saturate(80%);
  border-radius: 20px;
  height: calc(100vh - 120px);
}

.active-supplier-modal .ant-modal-content {
  background-color: transparent !important;
  border-radius: 20px !important;
  height: calc(100vh - 120px);
}

.supplier-see-more {
  color: var(--color-solid-darkestgrey);
  cursor: pointer;
}

.supplier-see-more:hover {
  color: var(--color-solid-darkblue);
}

#dashboard .cards-row {
  padding: 20px 25px;
}

#dashboard .card-type1 {
  border-radius: 10px !important;
  padding: 10px !important;
  cursor: pointer;
}

#dashboard .card-type1 {
  margin-bottom: 10px;
  border: 0;
}

#dashboard .card-type1 span {
  font-weight: 600;
  font-size: 15px;
}

#dashboard .card1 {
  background: var(--color-lightshade-orange);
  padding: 3px;
}

#dashboard .card1:hover {
  background: linear-gradient(
    30deg,
    rgba(252, 128, 28, 0.2733426134125525) 0%,
    rgba(252, 128, 28, 1) 100%
  );
  color: var(--color-solid-white);
  padding: 3px;
}

#dashboard .card2 {
  background: var(--color-lightshade-blue);
  padding: 3px;
}

#dashboard .card2:hover {
  background: linear-gradient(
    30deg,
    rgba(0, 129, 190, 0.31535942013524154) 0%,
    rgba(0, 129, 190, 1) 100%
  );
  color: var(--color-solid-white);
  padding: 3px;
}

#dashboard .card3 {
  background: var(--color-lightshade-grey);
  padding: 3px;
}

#dashboard .card3:hover {
  background: linear-gradient(
    30deg,
    rgba(112, 104, 220, 0.30135381789434523) 0%,
    rgba(112, 104, 220, 1) 100%
  );
  color: var(--color-solid-white);
  padding: 3px;
}

#dashboard .add-icon {
  width: 50%;
  color: var(--color-solid-black);
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
}

#dashboard .glass-card {
  min-height: calc(100vh - 444px) !important;
  height: 100% !important;
  padding: 0px !important;
  /* background: var(--color-card-glass); */
  background-color: var(--color-solid-white);
  border: 0.5px solid var(--color-border);
  border-radius: 16px !important;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

#dashboard .glass-card span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dashboard .glass-card .ant-card-body {
  padding: 10px !important;
}

.see-more-body {
  overflow-y: scroll !important;
  height: calc(100vh - 250px);
}

.see-more-header .ant-modal-title {
  color: var(--color-solid-darkerblue);
  font-size: 15px;
  font-weight: 600;
  padding-left: 20px;
}

.see-more-wrapper .ant-modal .ant-modal-content {
  border-radius: 14px;
}

.see-more-wrapper .ant-modal .ant-modal-content .ant-modal-close {
  margin-right: 20px;
}

#dashboard .card-type2 {
  min-height: calc(100vh - 424px) !important;
  height: 100% !important;
  background-color: var(--color-solid-white);
  border: 0.5px solid var(--color-border);
  border-radius: 16px !important;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

#dashboard .card-type2 .ant-empty {
  height: calc(100vh - 576px) !important;
  /* padding-top: 30px; */
}

.see-more-body .card-type2 {
  /* background: var(--color-card-glass); */
  background-color: var(--color-solid-white);
  border: 0.5px solid var(--color-border);
  border-radius: 16px !important;
}

#dashboard .card-type2 .ant-card-body,
.see-more-body .card-type2 .ant-card-body {
  border-radius: 14px !important;
  padding: 5px 15px 0px 15px !important;
}

#dashboard .card-type2 p,
.see-more-body .card-type2 p {
  color: var(--color-solid-darkerblue);
  font-size: 15px;
  font-weight: 600;
  padding: 10px 0px 10px 15px;
}

#dashboard .card-type2 .processed-list-items,
.see-more-body .card-type2 .processed-list-items,
#dashboard .card-type3 .events-list-items {
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
  display: flex;
}

#dashboard .card-type2 .view-text,
.see-more-body .card-type2 .view-text {
  font-weight: 600;
  font-size: 13px;
}

#dashboard .card-type2 p span,
.see-more-body .card-type2 p span {
  color: var(--color-solid-black);
}

#dashboard .card-type2-sub,
.see-more-body .card-type2-sub {
  background: var(--color-solid-white);
}

#dashboard .ant-card-bordered,
.see-more-body .ant-card-bordered {
  border: none;
}

#dashboard .card-type2-sub .ant-card-body,
.see-more-body .card-type2-sub .ant-card-body {
  background-color: var(--color-solid-whitegrey);
  border-radius: 14px !important;
  padding: 12px 15px !important;
  /* border: 2px solid #408bfc !important; */
}

#dashboard .card-type2-sub .ant-card-body:hover,
.see-more-body .card-type2-sub .ant-card-body:hover {
  background-color: #f3f3f3;
  border-radius: 14px !important;
  padding: 12px 15px !important;
}

#dashboard .card-type2-sub span,
.see-more-body .card-type2-sub span {
  color: var(--color-solid-black);
  font-size: 12px;
  font-weight: 400;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dashboard .card-type2-sub span a,
.see-more-body .card-type2-sub span a {
  color: var(--color-solid-darkblue);
  /* color: var(--color-solid-darkergrey); */
  text-align: right;
}

#dashboard .bottom-text {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

#dashboard .see-more {
  margin-top: 15px;
}

#dashboard .see-more a {
  color: var(--color-solid-darkgrey) !important;
  font-size: 13px;
  font-weight: 600;
}

#dashboard .see-more a:hover {
  color: var(--color-solid-darkerblue) !important;
  font-size: 13px;
  font-weight: 600;
}

#dashboard .card-type3 {
  /* background: var(--color-card-glass); */
  background-color: var(--color-solid-white);
  border: 0.5px solid var(--color-border);
  border-radius: 16px !important;
}

#dashboard .card-type3 .ant-card-body {
  border-radius: 14px !important;
  padding: 17px 17px 5px 17px !important;
}

#dashboard .card-type3 .to-do-list {
  color: var(--color-solid-darkgrey);
  font-weight: 400;
}

#dashboard .card-type3 p {
  color: var(--color-solid-darkblue);
  font-size: 15px;
  font-weight: 700;
  padding-left: 10px;
}

#dashboard .card-type3 p span {
  color: var(--color-solid-black);
}

#dashboard .card-type3-sub {
  background: var(--color-solid-white);
  cursor: pointer;
}

#dashboard .card-type3-sub:hover {
  background: var(--color-shade-yellow);
}

#dashboard .card-type3-sub .ant-card-body {
  border-radius: 14px !important;
  padding: 10px !important;
}

#dashboard .card-type3-sub span {
  color: var(--color-solid-black);
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dashboard .card-type3-sub span a {
  color: var(--color-solid-darkgrey);
  text-align: right;
  font-size: 10px;
}

#dashboard .card-type3-sub .sq-card {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}

#dashboard .card-type3-sub:hover .sq-card {
  background: var(--color-solid-white);
}

#dashboard .card-type3-sub .sq-card1 {
  background: var(--color-gradient-blue);
}

#dashboard .card-type3-sub .sq-card2 {
  background: var(--color-gradient-red);
}

#dashboard .card-type3-sub .sq-card3 {
  background: var(--color-gradient-orange);
}

#dashboard .card-type3-sub .sq-card4 {
  background: var(--color-gradient-purple);
}

/* STICKY HEADER FOR DASHBOARD STARTS */

/* #dashboard .sticky {
  position:fixed;
    left:0;
    top:0;
    right: 0;
    z-index: 10;
  background-color: transparent;
} */

.dashboard-content-layout {
  z-index: 1;
  /* margin-top: 200px; */
  overflow-y: scroll;
  height: calc(100vh - 117px);
  /* For Judo Background */
  /* background: var(--color-solid-backgroundgrey); */
  background: transparent;
}

.dashboard-content-layout::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-content-layout {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.widget-config-drawer,
.ant-drawer {
  z-index: 9999 !important;
}

/* STICKY HEADER FOR DASHBOARD ENDS */
