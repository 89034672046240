.commercial .ant-layout {
  background: transparent !important;
}

.commercial .ant-tabs-nav-wrap {
  justify-content: center;
}

.commercial .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.commercial .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.commercial .ant-tabs-nav::before {
  border-bottom: none !important;
}

.commercial .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
}

.commercial .ant-tabs-tab {
  padding: 0 !important;
}

.commercial .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.commercial .ant-tabs-tab-btn {
  width: 180px;
}

.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}

.commercial-tab-content {
  z-index: 1;
  margin-top: 16rem;
  overflow-y: scroll;
  height: calc(100vh - 16rem);
}

.commercial .switch-track {
  background: white;
  width: 50px;
  height: 23px;
  border-radius: 24px;
  cursor: pointer;
  border: 1px solid var(--color-border);
}

.commercial .switch {
  height: 17px;
  width: 17px;
  background-color: var(--color-solid-darkblue);
  border-radius: 50%;
  display: inline-block;
  margin: 2px 3px;
}

.commercial .switch-text {
  font-weight: 600;
  color: var(--color-solid-darkblue);
}

.commercial .sorting {
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  text-align: center;
  width: 9rem;
  height: 30px;
  padding: 5px;
  border-radius: 8px;
}

.commercial .expand {
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
}

.commercial-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkgrey) !important;
  text-transform: capitalize !important;
  font-weight: 700;
  font-size: 11px;
  padding: 9px 16px !important;
}

.commercial-table .ant-table-thead > tr > th:not(:last-child) {
  /* border-inline-end: none !important; */
  border-inline-end: 1px solid var(--color-border) !important;
}

.commercial-table .ant-table .ant-table-body table {
  border-spacing: 0 10px;
  margin-top: -10px;
}

.commercial-table .ant-table-container,
.commercial-table .ant-table table {
  border: 0 !important;
}

.commercial-table .ant-table .ant-table-body .ant-table-tbody .ant-table-row {
  background: var(--color-solid-white);
  /* cursor: pointer; */
}

.commercial-table .ant-table-tbody > tr > td {
  color: var(--color-solid-black);
  font-size: 12px;
  text-align: center;
  padding: 0 !important;
  /* border: none !important; */
}

.commercial-table .ant-table-tbody .ant-table-cell {
  padding: 8px 16px !important;
}

.commercial-table .ant-table-body > table > tbody > tr > td:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
  /* border-spacing: 10px; */
}

.commercial-table .ant-table {
  background: transparent !important;
}

.commercial-table td:first-child,
.commercial-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.commercial-table thead tr td:last-child {
  display: none !important;
}

.commercial-table .ant-table-thead > tr > th:nth-last-child(2) {
  border-radius: 0 10px 10px 0 !important;
  border-inline-end: none !important;
}

.commercial-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.commercial-table td:last-child,
.commercial-table th:last-child,
.commercial-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.commercial-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

.col-list {
  border: 1px solid var(--color-border);
  border-radius: 12px;
}

.col-list .select-btn,
.col-list .select-btn:hover {
  height: 30px;
  background: transparent;
  border: 1px solid var(--color-solid-darkgrey);
  color: var(--color-solid-darkgrey);
  border-radius: 12px;
  font-weight: 500;
  box-shadow: none;
}

.col-list .selected-btn,
.col-list .selected-btn:hover {
  height: 30px;
  border: 1px solid var(--color-solid-darkblue);
  background: var(--color-solid-darkblue) !important;
  border-radius: 12px;
  font-weight: 500;
  box-shadow: none;
}

.col-editor-head {
  font-weight: 400;
  font-size: 13px;
  color: var(--color-solid-darkgrey);
}

.col-editor-save {
  font-weight: 500;
  font-size: 13px;
  color: var(--color-solid-darkblue);
  cursor: pointer;
}

.col-edit-modal .ant-modal-content {
  border-radius: 24px !important;
}
