.system-setup .ant-layout,
.price-profile .ant-layout {
  background: transparent !important;
}

.ant-table-column-sorter {
  display: none !important;
}

.ant-table-column-sorters .ant-table-column-title .sorted-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  /* font-family: "Heebo", sans-serif !important; */
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.glass-div {
  border-radius: 20px;
  margin: 15px 20px;
  padding: 15px 20px;
  border: 1px solid var(--color-border);
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
}

.glass-div-form {
  border-radius: 20px;
  margin: 15px 20px;
  /* padding: 5px 20px 15px 20px; */
  /* border: 1px solid var(--color-border); */
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  color: var(--color-solid-darkgrey);
}

.card-div {
  margin: 20px 0px;
}

.div-head {
  color: var(--color-solid-darkblue);
}

.has-search {
  /* padding: 10px 11px !important; */
  background: transparent !important;
}

.div-head .ant-image {
  float: right;
}

.setup-card .ant-card-body {
  border-radius: 12px !important;
  padding: 8px !important;
  cursor: pointer;
}

.setup-header-card .ant-card-body {
  padding: 5px 20px !important;
}

.setup-header-card2 .ant-card-body {
  padding: 6px 20px !important;
}

#header-main-card .ant-card-body {
  padding: 0px 10px !important;
}

#user-management-main-card .ant-card-body {
  padding: 5px 10px !important;
}

.setup-header-card {
  /* border-radius: 24px !important; */
  /* background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  ) !important; */
  background-color: var(--color-solid-white);
}

.setup-header-card2 .role-name-text {
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.setup-header-card2 .latest-contract-text {
  font-weight: 600;
  font-size: 12px;
  padding-left: 30px;
  color: #1180fb;
}

.setup-header-card2 .text-overflow {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.setup-header-card2 .last-update-text {
  color: var(--color-solid-darkgrey);
}

.system-setup1 {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 50px);
  overflow-x: hidden;
}

.system-setup1 .view-group-div {
  padding: 10px 20px 10px 20px;
}

.system-setup1 .scrolling-content {
  z-index: 1;
  overflow-y: scroll;
  margin-top: -10px;
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 117px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.system-setup1 .view-roles-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px;
  height: calc(100vh - 183px);
  overflow: auto;
}

.system-setup1 .contracts-control-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px;
  height: calc(100vh - 227px);
}

.system-setup1 .system-setup-control-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px;
  height: calc(100vh - 183px);
  margin-left: 5px;
  overflow: auto;
}

.system-setup1 .legal-view-div {
  background-color: var(--color-solid-white);
  border-radius: 0 20px 20px 20px;
  /* margin-right: 5px; */
  padding: 5px;
  height: calc(100vh - 164px);
  overflow: auto;
}

.system-setup1 .org-view-div {
  background-color: var(--color-solid-white);
  border-radius: 0px 20px 20px 20px;
  /* margin-right: 5px; */
  padding: 5px;
  height: calc(100vh - 164px);
  overflow: auto;
}

.system-setup1 .org-view-div .entities-scroll {
  padding-bottom: 25px;
}

/* .system-setup1 .org-view-div .plus-div {
  position: relative;
} */

.system-setup1 .control-heading {
  padding: 10px 0px 0px 20px;
  font-weight: 600;
  color: var(--color-solid-darkergrey);
}

.system-setup1 .control-toggles {
  padding: 2px 20px 20px 20px;
}

.system-setup1 .control-individual-toggles {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  padding: 8px 15px 8px 15px;
  border-radius: 10px;
}

.system-setup1 .pipeline-search {
  height: 38px;
  padding: 0px 20px;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
}

.system-setup1 .org-view-div .org-form-label {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-solid-lightgrey);
}

.system-setup1 .org-view-div .org-form-label .ant-form-item-label {
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ddf4ff;
  padding-left: 45px;
}

.system-setup1
  .org-view-div
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon,
.system-setup1
  .legal-view-div
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon,
.system-setup1
  .create-supplier-div
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon {
  font-size: 13px;
  color: var(--color-solid-darkergrey);
  font-weight: 600;
}

.system-setup1
  .org-view-div
  .org-form-label
  .ant-form-item-label
  .ant-form-item-no-colon,
.system-setup1
  .create-supplier-div
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon {
  color: var(--color-solid-darkergrey);
  font-size: 13px;
  font-weight: 600;
}

.system-setup1
  .org-view-div
  .ant-col:first-child
  .org-form-label
  .ant-form-item-label {
  border-radius: 15px 0 0 15px;
}

.system-setup1
  .org-view-div
  .ant-col:last-child
  .org-form-label
  .ant-form-item-label {
  border-radius: 0px 15px 15px 0px;
}

.system-setup1 .org-view-div .document-item {
  border-bottom: var(--color-solid-lightgrey) solid 2px;
}

.system-setup1 .org-view-div .contract-allocation-div {
  position: absolute;
  bottom: 5px;
  background-color: #f1f1f1;
  color: var(--color-solid-darkergrey);
  font-weight: 600;
  font-size: 13px;
  padding: 15px 45px 15px 45px;
  border-radius: 15px;
  min-width: 99%;
}

.system-setup1 .contracts-control-div .view-supplier-hr,
.system-setup1 .create-entity-div .view-supplier-hr,
.system-setup1 .create-table-entity-div .view-supplier-hr,
.system-setup1 .create-upload-entity-div .view-supplier-hr,
.system-setup1 .legal-documents-div .view-supplier-hr {
  border-top: 0.1rem solid var(--color-solid-lightgrey);
}

.system-setup1 .contracts-control-div .documents-text {
  padding: 5px 10px 0px 10px;
}

.system-setup1 .legal-documents-div {
  background-color: var(--color-solid-white);
  border-radius: 20px;
  padding: 10px;
  height: calc(100vh - 230px);
}

.system-setup1 .legal-documents-div .documents-text {
  padding: 5px 10px 0px 10px;
}

.system-setup1 .create-supplier-div {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 5px 20px;
  overflow-y: auto;
  height: calc(100vh - 130px);
}

.system-setup1 .create-entity-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
}

.system-setup1 .create-upload-entity-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
  padding: 0px 0px 30px 0px;
}

.system-setup1 .create-table-entity-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
}

.system-setup1 .choose-input-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
  padding: 10px 10px 0px 10px;
}

.upolad-input-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
  padding: 10px 10px 0px 10px;
}

.upolad-input-div .ant-upload-wrapper .ant-upload-select {
  display: block !important;
}

.system-setup1 .choose-input-div .ant-upload-wrapper .ant-upload-select {
  display: block !important;
}

.system-setup1 .create-entity-div .entity-text,
.system-setup1 .entity-text,
.contract-pipeline .entity-text {
  color: var(--color-solid-darkergrey);
}

.create-supplier-div .org-supplier-label .ant-input,
.org-view-div .org-supplier-label .ant-input {
  padding: 8px 15px 10px 15px;
}

.system-setup1 .contracts-control-div .notes-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: 13px;
  left: 19px;
  background-color: #f1f1f1;
  color: var(--color-solid-darkgrey);
  font-weight: 600;
  font-size: 13px;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  min-width: 90%;
}

#edit-notes-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: 33px;
  left: 19px;
  background-color: #f1f1f1;
  color: var(--color-solid-darkgrey);
  font-weight: 600;
  font-size: 13px;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  min-width: 90%;
}

.system-setup1 .contracts-control-div .notes-icons {
  display: flex;
  margin-left: auto;
}

.cancel-btn,
.cancel-btn:hover {
  height: 35px !important;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-red) !important;
  border-radius: 20px !important;
  color: var(--color-solid-red) !important;
  font-weight: 600 !important;
}

.upload-btn,
.upload-btn:hover {
  height: 35px;
  box-shadow: none !important;
  background: var(--color-shade-blue) !important;
  border: 1px solid var(--color-solid-lightblue);
  border-radius: 20px;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
}

.save-btn {
  height: 35px;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-darkblue);
  border-radius: 20px;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
}

.disable-btn,
.disable-btn:hover {
  height: 35px;
  background: transparent !important;
  box-shadow: none !important;
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 20px;
  color: var(--color-solid-darkgrey) !important;
  font-weight: 600;
  cursor: not-allowed;
  pointer-events: none;
}

.draft-btn,
.draft-btn:hover {
  height: 35px;
  background: transparent !important;
  box-shadow: none !important;
  border: 1px solid var(--color-solid-darkblue);
  border-radius: 20px;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
}

.finish-btn {
  height: 40px;
  border-radius: 20px;
  font-weight: 600;
}

.price-profile .radio-group {
  width: 100%;
  margin: 10px 10px 0 20px;
}

.price-profile .radio-group span {
  color: var(--color-solid-darkgrey);
  font-size: 15px;
}

.price-profile .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 3px solid var(--color-border);
}

.price-profile .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--color-solid-darkblue) !important;
  border: 3px solid var(--color-border) !important;
}

.price-profile .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-solid-darkblue) !important;
}

.price-profile .ant-select-selector,
.invoice-profile .ant-select-selector {
  background: transparent !important;
  border: 1px solid var(--color-solid-lightgrey) !important;
  /* text-align: center !important; */
  padding: 4px !important;
  height: 40px !important;
}

.price-profile .ant-collapse {
  background: transparent !important;
}

.price-profile .ant-collapse-item {
  border-bottom: none !important;
}

.select-btn {
  height: 40px;
  background: transparent;
  border: 1px solid var(--color-solid-darkgrey);
  color: var(--color-solid-darkgrey);
  border-radius: 10px;
  font-weight: 600;
}

.selected-btn,
.selected-btn:hover {
  height: 40px;
  border: 1px solid var(--color-solid-lightblue);
  background: var(--color-solid-lightblue) !important;
  border-radius: 10px;
  font-weight: 600;
}

.price-profile .ant-tree .ant-tree-treenode:not(:last-child),
.contract-template .ant-tree .ant-tree-treenode:not(:last-child),
.invoice-profile .ant-tree .ant-tree-treenode:not(:last-child) {
  padding: 0 0 20px 0 !important;
}

.price-profile .ant-tree-node-selected,
.invoice-profile .ant-tree-node-selected,
.contract-template .ant-tree-node-selected,
.price-profile .ant-tree,
.invoice-profile .ant-tree,
.contract-template .ant-tree,
.price-profile .ant-tree .ant-tree-checkbox + span:hover,
.contract-template .ant-tree .ant-tree-checkbox + span:hover,
.invoice-profile .ant-tree .ant-tree-checkbox + span:hover {
  background: transparent !important;
}

.selected-clause .ant-tree .ant-tree-checkbox,
.price-profile .ant-tree .ant-tree-checkbox + span,
.contract-template .ant-tree .ant-tree-checkbox + span,
.invoice-profile .ant-tree .ant-tree-checkbox + span {
  cursor: default;
}

.no-data {
  text-align: center;
  padding: 7px 0px;
}

.org-lists,
.contract-lists {
  display: flex;
  justify-content: center;
  list-style: none;
}

.org-lists li {
  text-align: center;
  padding: 10px 5px;
  margin: 0px 40px;
}

.contract-lists li {
  text-align: center;
  padding: 10px 5px;
  margin: 0px 40px;
}

@media only screen and (max-width: 614px) {
  .org-lists li {
    text-align: center;
    padding: 10px 5px;
    margin: 0px 20px;
  }
}

/* .system-setup .ant-tabs-nav-wrap {
  justify-content: center;
}

.system-setup .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.system-setup .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.system-setup .ant-tabs-nav::before {
  border-bottom: none !important;
}

.system-setup .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
}

.system-setup .ant-tabs-tab {
  padding: 0 !important;
}

.system-setup .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.system-setup .ant-tabs-tab-btn {
  width: 180px;
} */

.tabs-ss-title {
  display: flex;
  padding: 0px 10px 0px 10px;
}

.tabs-ss-title > img {
  /* padding-right: 10px; */
  width: 20px;
  height: 20px;
}

.tabs-ss-title > p {
  padding-left: 10px;
  font-size: 13px;
}

.org-setup-div .ant-tabs .ant-tabs-tab-btn .tabs-ss-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.org-setup-div .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn .tabs-ss-title {
  font-weight: 600;
  color: var(--color-solid-black);
  text-shadow: none;
}

.org-setup-div .org-setup-tabs .ant-tabs-nav {
  background-color: var(--color-solid-white);
  margin: 0;
}

.org-setup-div .org-setup-tabs .ant-tabs-nav-wrap {
  padding-left: 25px;
  background-color: var(--color-solid-white);
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--color-solid-white);
  border: none;
  transition: none;
}

.org-setup-div .org-setup-tabs .ant-tabs .ant-tabs-tab-btn .tabs-ss-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.org-setup-div
  .org-setup-tabs
  .ant-tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .tabs-ss-title {
  font-weight: 600;
  color: var(--color-solid-black);
  text-shadow: none;
}

.org-setup-div .org-setup-tabs .ant-tabs .ant-tabs-tab-active {
  background-color: var(--color-solid-backgroundgrey) !important;
  border: none;
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-backgroundgrey);
  border: none;
  /* margin-right: 10px; */
  transition: none;
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-active:before,
.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-backgroundgrey);
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-backgroundgrey);
}

.org-setup-div .org-setup-tabs .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0;
}

.org-setup-div .org-setup-tabs .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.system-setup1 .ant-tabs-tab-active .ant-tabs-tab-btn,
.system-setup1 .spaced-out-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 2px 2px 0 0;
  padding: 8px 26px !important;
  background-color: var(--color-solid-white) !important;
  color: var(--color-solid-) !important;
  text-align: center;
  text-shadow: 0 0 1px var(--color-solid-darkergrey);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.03em;
}

.system-setup1 .ant-tabs-tab .ant-tabs-tab-btn,
.system-setup1 .spaced-out-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 8px 26px !important;
  text-align: center;
  color: var(--color-solid-darkergrey);
  font-size: 13px;
  text-shadow: 0 0 1px var(--color-solid-darkergrey);
  letter-spacing: 0.03em;
  transition: none;
}

.system-setup1 .ant-tabs-nav::before,
.system-setup1 .spaced-out-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.system-setup1 .ant-tabs-nav-list {
  border: none;
  background: var(--color-solid-backgroundgrey);
  border-radius: 24px;
  width: 100%;
}

.system-setup1 .ant-tabs-tab,
.system-setup1 .spaced-out-tab .ant-tabs-tab {
  padding: 0 !important;
  margin: 0 !important;
}

.system-setup1 .ant-tabs-ink-bar,
.system-setup1 .spaced-out-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

/* .system-setup1 .ant-tabs-tab-btn,
.system-setup1 .spaced-out-tab .ant-tabs-tab-btn {
  width: 180px;
} */
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: initial; /* Removes the color */
}

.system-setup1 .ant-tabs-nav .ant-tabs-tab-disabled .ant-tabs-tab-btn {
  cursor: default !important;
  padding: 0 !important;
  margin-left: 3px;
}

.system-setup1 .ant-tabs .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.system-setup1 .ant-tabs-nav .ant-tabs-tab-active:before,
.system-setup1 .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.system-setup1 .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-white);
}

.system-setup1 .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-white);
}

.system-setup1 .ant-tabs-nav {
  margin: 0;
}

.system-setup1 .spaced-out-tab .ant-tabs-nav-list {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
}

/* .system-setup1 {
  background-color: var(--color-solid-backgroundgrey);
} */

.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
}

/* .system-setup-tabs {
  display: none;
} */

.setup-tab-content {
  /* z-index: 1; */
  /* margin-top: 16rem; */
  /* overflow-y: scroll; */
  height: 85vh;
  background-color: var(--color-solid-backgroundgrey);
}

.setup-tab-content::-webkit-scrollbar,
.price-profile-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.setup-tab-content,
.price-profile-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.price-profile-content {
  z-index: 1;
  /* margin-top: 12rem; */
  overflow-y: scroll;
  /* height: calc(100vh - 12rem); */
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 127px);
}

.pp-top {
  display: flex;
  justify-content: space-between;
}

.pp-search-input,
.pp-search-input:hover {
  border: 1px solid var(--color-solid-lightgrey) !important;
  outline: none;
  border-radius: 10px;
  padding: 0px 11px;
  background: var(--color-card-glass);
  height: 35px;
}

.pp-search-input .ant-input {
  background-color: transparent !important;
}

.pp-search-input1,
.pp-search-input1:hover {
  border: 1px solid var(--color-solid-lightgrey) !important;
  outline: none;
  border-radius: 10px;
  padding: 0px 11px;
  background: var(--color-card-glass);
  height: 35px;
}

.pp-search-input1 .ant-input {
  background-color: transparent !important;
}

.pp-create-btn {
  /* border: 1px solid var(--color-solid-darkblue); */
  width: 100%;
  text-align: center;
  border-radius: 20px;
  padding: 8px;
  color: var(--color-solid-white) !important;
  font-weight: 600;
  background-color: #2684ff;
}

.pp-create-btn > a {
  color: var(--color-solid-white) !important;
}

.pp-create-btn:hover {
  /* border: 1px solid var(--color-solid-darkblue); */
  width: 100%;
  text-align: center;
  border-radius: 20px;
  padding: 8px;
  color: var(--color-solid-white) !important;
  font-weight: 600;
  background-color: #4096ff;
}

.pp-create-btn1 {
  border: 1px solid var(--color-solid-darkblue);
  width: 10%;
  text-align: center;
  border-radius: 20px;
  padding: 8px;
  color: var(--color-solid-darkblue);
  font-weight: 600;
}

.checkbox-search {
  width: 30%;
}

.checkbox-group {
  max-width: 30%;
  max-height: 150px;
  overflow-y: auto;
}

.ant-checkbox-group-item {
  display: block;
}

.ant-checkbox-wrapper {
  display: flex;
  margin-inline-start: 0% !important;
}

.ant-checkbox-group-item > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.hide-value .ant-select-selection-overflow {
  /* display: none !important; */
  visibility: hidden !important;
}

.selected-items {
  width: 60%;
  /* height: 150px; */
  padding: 10px;
  border: 1px solid var(--color-solid-lightblue);
  border-radius: 10px;
  color: var(--color-solid-lightblue);
  background-color: #fafafa;
}

.display-items .ant-tag {
  background-color: var(--color-shade-blue);
  color: var(--color-solid-darkblue);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 0.1rem solid var(--color-solid-darkgrey);
  margin: 1em 0;
  padding: 0;
}

.glass-div-create-pp-form {
  border-radius: 20px;
  margin: 15px 20px;
  background: var(--color-solid-white);
  color: var(--color-solid-darkgrey);
}

/******** Data Table CSS *****************/
#commercial-days-costs .ant-table-thead > tr > th,
#total-commercial-days-costs .ant-table-thead > tr > th {
  background: var(--color-solid-lightgrey) !important;
  color: var(--color-solid-darkestgrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 15px !important;
  text-align: center !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#commercial-days-costs .ant-table-tbody .ant-table-cell {
  font-size: 13px !important;
  /* font-weight: 100 !important; */
  padding: 0px 5px !important;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkestgrey);
}

#total-commercial-days-costs
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:first-child {
  border-bottom-left-radius: 0px !important;
}

#total-commercial-days-costs
  .ant-table-tbody
  > tr:last-child
  > .ant-table-cell:last-child {
  border-bottom-right-radius: 0px !important;
}

#total-commercial-days-costs .ant-table-tbody .ant-table-cell {
  font-size: 13px !important;
  /* font-weight: 100 !important; */
  padding: 5px 5px !important;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkestgrey);
}

#total-commercial-days-costs
  .ant-table-tbody
  .ant-table-cell:not(:first-child) {
  text-align: end !important;
}

#total-commercial-days-costs
  .ant-table-summary
  .ant-table-cell:not(:first-child) {
  text-align: end !important;
}

#total-commercial-days-costs
  .ant-table-summary
  .ant-table-cell:not(:last-child) {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

#total-commercial-days-costs .ant-table-summary .ant-table-cell:first-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  border-left: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-left-radius: 10px !important;
}

#total-commercial-days-costs .ant-table-summary .ant-table-cell:last-child {
  padding: 4px !important;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-solid-darkerblue);
  border-left: 1px solid var(--color-solid-black);
  border-right: 1px solid var(--color-solid-black);
  border-bottom: 1px solid var(--color-solid-black);
  border-bottom-right-radius: 10px !important;
}

/* ***************************Role managemnent */

#RoleManagement-table th {
  background-color: red;
}

/******** Data Table CSS *****************/

.system-setup-table .ant-table-thead > tr > th {
  /* background: var(--color-lightshade-blue) !important; */
  background-color: rgb(221, 238, 255) !important; /* background for header*/
  /* color: var(--color-solid-darkgrey) !important; */
  color: var(
    --color-solid-black
  ) !important; /* add black color to the header */
  text-transform: capitalize !important;
  font-weight: 600;
  /* font-size: 13px; */
  text-align: center !important;
  /* padding: 12px 16px !important; */
  padding: 8px 5px 8px 5px !important;
}

.system-setup-table .ant-table-thead > tr > td:first-child {
  background: var(--color-lightshade-blue) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  text-align: center !important;
  padding: 4px 16px !important;
  border: none !important;
}

/* .system-setup-table .ant-table-body > table > tbody > tr > td:first-child {
  border: none !important;
} */

.system-setup-table .ant-table-thead > tr > th:not(:last-child) {
  /* border-inline-end: none !important; */
  /* border-inline-end: 1px solid var(--color-border) !important; */
}
.system-setup-table .ant-table-tbody > tr > td:not(:last-child) {
  border-inline-end: 0px solid var(--color-border) !important;
}

.system-setup-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(:nth-last-child(2)):not(:first-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}
/* table header last tab */
.system-setup-table .ant-table-thead > tr > th:last-child {
  border-inline-start: 0px solid var(--color-border) !important;
}

.system-setup-table .ant-table-thead > tr > th > *:first-child {
  /* grid-column: span 2; */
  border-inline-end: 0px solid var(--color-border) !;
}

.system-setup-table .ant-table .ant-table-body table {
  border-spacing: 0 1px;
  margin-top: -2px;
}

.system-setup-table .ant-table-cell .name {
  text-align: left !important;
  padding-left: 10px;
}

.system-setup-table .ant-table-cell .name .ant-image {
  margin-right: 10px;
}

/* #invoice-create-table .ant-table .ant-table-body table {
  border-spacing: 0px !important;
  margin-top: 0px;
} */

.system-setup-table .ant-table-container,
.system-setup-table .ant-table table {
  border: 0 !important;
}

/* .system-setup-table .ant-table .ant-table-body .ant-table-tbody .ant-table-row {
  background: var(--color-solid-white);
  cursor: pointer;
} */

.system-setup-table .ant-table-tbody > tr > td {
  /* color: var(--color-solid-black); */
  color: var(--color-solid-darkestgrey);
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  padding: 0 !important;
  /* border: none !important; */
}

#invoice-create-table .ant-table-tbody .ant-table-cell {
  padding: 22px 25px 0px !important;
}

.system-setup-table .ant-table-tbody .ant-table-cell {
  padding: 6px 14px !important;
  background-color: var(--color-solid-white);
}

#supplier-setup-table .ant-table-body > table > tbody > tr > td:first-child {
  border: none !important;
}
#supplier-setup-table .ant-table-body > table > tbody > tr > td:last-child {
  border: none !important;
}
#legalEntity-setup-table .ant-table-body > table > tbody > tr > td:first-child {
  /* border: none !important; */
  width: 40px;
}
#costCenter-setup .ant-table-body > table > tbody > tr > td:first-child {
  /* border: none !important; */
  width: 40px;
}
#legalEntity-setup-table th:first-child,
#costCenter-setup th:first-child,
#FX-setup-table th:first-child,
#Rate-card-table th:first-child,
#Priceing-profile th:first-child,
#Invoicing-profile th:first-child,
#WorkFlow-setup th:first-child,
#ClauseLibrary-table th:first-child,
#Template-table th:first-child,
#ContractList-table-setup th:first-child,
#Rolemanagement-table th:first-child {
  border-inline-end: 1px solid var(--color-border); /* Customize border width for the table header first child  */
}

.system-setup-table .ant-table-thead > tr > th:first-child {
  border-inline-end: 1px solid var(--color-border);
}

/* antdicon */
.Meatball-icon {
  color: var(--color-solid-darkergrey) !important;
}
.system-setup-table
  .ant-table-body
  > table
  > tbody
  > tr
  > td:not(:last-child):not(:nth-last-child(2)) {
  /* background-color: red !i mportant; */
  border-inline-end: 1px solid var(--color-border) !important;
  /* border-spacing: 10px; */
}

.system-setup-table .ant-table {
  background: transparent !important;
}

.system-setup-table td:first-child,
.system-setup-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.system-setup-table thead tr td:last-child {
  display: none !important;
}

.system-setup-table .ant-table-thead > tr > th:nth-last-child(2) {
  /* border-radius: 0 10px 10px 0 !important; */
  /* border-inline-end: none !important; */
  border-inline-start: 0px solid var(--color-border) !important;
}

.system-setup-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.system-setup-table td:last-child,
.system-setup-table th:last-child,
.system-setup-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
} /* add border radus for the right side of the row*/

.system-setup-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

#ratecard-create-table .ant-form-item {
  margin-bottom: 0 !important;
}

#ratecard-create-table .ant-input-number,
.system-setup-table .ant-input-number {
  height: 42px !important;
}

#ratecard-create-table .ant-input-number-input,
.system-setup-table .ant-input-number-input {
  height: 40px !important;
  padding-left: 20px !important;
}

/********************************************************* 
**********************************************************
**********************************************************
Create Supplier CSS Starts
**********************************************************
**********************************************************
**********************************************************/

.supplier-name-input {
  width: 30% !important;
}

.upload-note {
  color: var(--color-solid-darkgrey);
  margin: 0 15px 0;
}

.year-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.year-select .ant-select-selection-item {
  color: var(--color-solid-darkgrey);
  font-weight: 600;
}

.supplier-create-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  /* color: var(--color-solid-darkgrey) !important; */
  color: var(--color-solid-darkergrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 18px 26px !important;
}

.supplier-create-table .ant-table .ant-table-body table {
  border-spacing: 0 7px;
}

.supplier-create-table .ant-table-container,
.system-setup-table .ant-table table {
  border: 0 !important;
}

.supplier-create-table .ant-table-tbody > tr > td {
  /* color: var(--color-solid-black); */
  color: var(--color-table-data);
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  padding: 0 !important;
  /* border: none !important; */
}

#supplier-create-table .ant-table-tbody .ant-table-cell {
  padding: 22px 8px 0px !important;
}

#ratecard-create-table .ant-table-tbody .ant-table-cell {
  padding: 22px 25px 22px !important;
}

.org-view-div {
  scrollbar-color: #fff !important;
}

.supplier-create-table .ant-table-tbody .ant-table-cell {
  padding: 12px 14px !important;
  background-color: var(--color-solid-white);
}

.supplier-create-table
  .ant-table-body
  > table
  > tbody
  > tr
  > td:not(:last-child) {
  border: none !important;
}

.supplier-create-table .ant-table {
  background: transparent !important;
}

.supplier-create-table td:first-child,
.supplier-create-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.supplier-create-table thead tr td:last-child {
  display: none !important;
}

.supplier-create-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.supplier-create-table td:last-child,
.supplier-create-table th:last-child,
.supplier-create-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.supplier-create-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

.supplier-create-table .ant-form-item .ant-input {
  padding: 10px 20px 10px 20px;
}

.supplier-create-table .ant-select,
.system-setup-table .ant-select,
.create-supplier-div .org-supplier-label .ant-select,
.org-view-div .org-supplier-label .ant-select {
  height: 42px;
}

.supplier-create-table .ant-select-selector,
.system-setup-table .ant-select-selector,
.create-supplier-div .org-supplier-label .ant-select-selector,
.org-view-div .org-supplier-label .ant-select-selector {
  padding: 0px 0px 0px 10px !important;
  height: 42px !important;
}

.org-view-div .org-supplier-label .ant-select-disabled .ant-select-selector {
  background: var(--color-solid-white) !important;
  font-size: 14px !important;
  /* font-weight: 100 !important; */
}

.org-view-div .org-supplier-label .ant-picker {
  background: var(--color-solid-white) !important;
}

.org-view-div
  .org-supplier-label
  .ant-picker
  .ant-picker-input
  > input[disabled] {
  color: var(--color-solid-black) !important;
  cursor: default !important;
}

/* .supplier-create-table .ant-select-selection-search-input,
.system-setup-table .ant-select-selection-search-input,
.create-supplier-div .org-supplier-label .ant-select-selection-search-input {
  padding: 10px 0px 0px 10px !important;
} */

.supplier-create-table .ant-checkbox-wrapper,
.system-setup-table .ant-checkbox-wrapper,
.create-supplier-div .org-supplier-label .ant-checkbox-wrapper {
  padding-top: 3px !important;
}

.supplier-create-table .ant-select-selection-placeholder,
.system-setup-table .ant-select-selection-placeholder,
.create-supplier-div .org-supplier-label .ant-select-selection-placeholder {
  font-weight: lighter;
}

.system-setup-table .ant-input {
  height: 42px !important;
}

.supplier-create-table .ant-select-disabled .ant-select-selector {
  background: var(--color-solid-white) !important;
}

/********************************************************* 
**********************************************************
**********************************************************
Create Supplier CSS Ends
**********************************************************
**********************************************************
**********************************************************/

/********************************************************* 
**********************************************************
**********************************************************
FX Setup CSS Starts
**********************************************************
**********************************************************
**********************************************************/
.org-view-div .fx-year .ant-select-selector {
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white);
  /* padding: 5px 10px 5px 10px; */
  border-radius: 10px;
  border: none;
  padding-left: 20px;
  padding-top: 1px;
}

.org-view-div .fx-year .ant-select-arrow {
  color: var(--color-solid-white);
}

.org-view-div .fx-year .ant-select-selection-item {
  color: var(--color-solid-white);
}

#fx-setup-table .ant-table-tbody .ant-table-cell:not(:first-child) {
  border-top: 1px solid var(--color-solid-black) !important;
  border-bottom: 1px solid var(--color-solid-black) !important;
}

#fx-setup-table .ant-table-tbody .ant-table-cell:first-child {
  border-left: 1px solid var(--color-solid-black);
  border-top: 1px solid var(--color-solid-black) !important;
  border-bottom: 1px solid var(--color-solid-black) !important;
}

#fx-setup-table .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
}

.fx-setup-switch {
  background-color: var(--color-solid-darkgrey) !important;
}

.ant-switch-handle::before {
  background-color: var(--color-solid-white) !important;
}

.fxsetup-status {
  color: var(--color-solid-darkblue);
  font-weight: 600;
}

.inner-table .ant-table td {
  background-color: var(--color-lightshade-grey);
}

.inner-table .ant-table {
  margin-inline: 0 !important;
  padding: 10px 0;
}

/* 
.system-setup-table .ant-table-tbody .ant-table-expanded-row .ant-table-cell{
  padding: 12px 14px !important;
} */

.inner-table::before,
.inner-table::after {
  display: none;
}

.system-setup-table .ant-table-thead > tr > td {
  background-color: var(--color-lightshade-blue);
}

.system-setup-table td:first-child,
.system-setup-table th:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
} /* row border radius leftside*/

.hide-add {
  display: none;
}

.supplier-collapse-details {
  width: 100%;
  color: var(--color-solid-black);
  font-weight: 500;
  padding: 15px;
}

.supplier-collapse-details h4 {
  color: var(--color-solid-darkgrey) !important;
  font-weight: 600;
  margin-bottom: 20px;
}

.collapse-head {
  color: var(--color-solid-darkblue);
  border-bottom: 1px solid var(--color-border);
}

.system-setup1 .ant-modal .ant-modal-content {
  background-color: #4096ff !important;
}

.modal-title {
  color: #0092ff;
  text-align: center;
}

.modal-content {
  text-align: center;
  margin-top: 10px;
}

#workflow-table .ant-table-body table {
  border-spacing: 0 0px !important;
  margin-top: 0px !important;
}

#workflow-table .ant-table-thead > tr > th {
  background: var(--color-solid-lightgrey) !important;
  color: var(--color-solid-black) !important;
  text-transform: capitalize !important;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 12px 16px !important;
  border: 1px solid var(--color-solid-black);
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#workflow-table .ant-table-tbody .ant-table-cell:not(:first-child) {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
}

#workflow-table .ant-table-tbody .ant-table-cell:first-child {
  border-bottom: 1px solid var(--color-solid-black) !important;
  border-left: 1px solid var(--color-solid-black) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

#workflow-table .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid var(--color-solid-black) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0px !important;
}

#workflow-table .ant-table-tbody > tr:last-child > .ant-table-cell:first-child {
  border-bottom-left-radius: 10px !important;
}

#workflow-table .ant-table-tbody > tr:last-child > .ant-table-cell:last-child {
  border-bottom-right-radius: 10px !important;
}

#workflow-table .ant-table-tbody > tr > td .ant-form-item {
  margin: 0;
}

.workflow-setup-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkergrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 12px 16px !important;
}

.workflow-setup-table .ant-table-thead > tr > th:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.workflow-setup-table .ant-table .ant-table-body table {
  border-spacing: 0 7px;
  margin-top: -7px;
}

.workflow-setup-table .ant-table-cell .name {
  text-align: left;
  padding-left: 10px;
}

.workflow-setup-table .ant-table-cell .name .ant-image {
  margin-right: 10px;
}

.workflow-setup-table .ant-table-container,
.system-setup-table .ant-table table {
  border: 0 !important;
}

.workflow-setup-table .ant-table-tbody > tr > td {
  color: var(--color-table-data);
  font-weight: 600;
  font-size: 12px;
  /* text-align: center; */
  /* padding: 0 !important; */
}

.workflow-setup-table .ant-table-tbody .ant-table-cell {
  width: 10%;
  /* padding: 12px 14px !important; */
  background-color: var(--color-solid-white);
}

.workflow-setup-table
  .ant-table-body
  > table
  > tbody
  > tr
  > td:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.workflow-setup-table .ant-table {
  background: transparent !important;
}

.workflow-setup-table td:first-child,
.workflow-setup-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.workflow-setup-table thead tr td:last-child {
  display: none !important;
}

.workflow-setup-table .ant-table-thead > tr > th:nth-last-child(2) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.workflow-setup-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.workflow-setup-table td:last-child,
.workflow-setup-table th:last-child,
.workflow-setup-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.workflow-setup-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

.workflow-setup-table .ant-table-tbody > tr > td:not(:first-child) {
  text-align: left;
}

.modal-image {
  display: block;
  margin: 0 auto;
}

.modal-popup-footer {
  padding: 0px 20px 0px 20px;
}

.modal-popup-footer-wf {
  padding: 0px !important;
}

.modal-popup-footer .workflow-modal-btn {
  margin-left: 10px;
  padding: 8px 10px 8px 10px;
  background-color: var(--color-solid-darkerblue);
  color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
}

.workflow-setup-table .ant-select {
  width: 80%;
}

.workflow-setup-table .ant-select-selector {
  border: none !important;
}

.setup-header-card2 .roletab .ant-input {
  width: 40%;
}

.setup-header-card2 .ant-form-item {
  margin-bottom: 0 !important;
}

.create-contract-tree-col-2 .tree-struc .create-contract-search {
  position: absolute;
  top: 0px;
  background-color: var(--color-solid-white);
}

.maincard .ant-card .ant-card-body {
  padding: 0px !important;
}

.maincard .ant-card-bordered {
  border: 0px !important;
}

/* Workflow CSS */
.wf-type-btn,
.wf-type-btn:hover {
  background-color: var(--color-solid-darkblue) !important;
  box-shadow: none !important;
  border: 1px solid var(--color-solid-darkblue) !important;
  border-radius: 13px !important;
  color: var(--color-solid-white) !important;
  font-weight: 600 !important;
  /* padding: 0px 30px 0px 30px !important; */
}

.wf-create-btn,
.wf-create-btn:hover {
  background-color: var(--color-solid-darkblue) !important;
  box-shadow: none !important;
  border: 1px solid var(--color-solid-darkblue) !important;
  border-radius: 20px !important;
  color: var(--color-solid-white) !important;
  font-weight: 600 !important;
  padding: 0px 30px 0px 30px !important;
  height: 38px;
  width: 100%;
}

.create-wf-modal .ant-modal-content {
  border-radius: 20px !important;
  padding: 20px 20px 35px 20px !important;
}

.pricing-div {
  background-color: var(--color-solid-white);
  border: 1px solid var(--color-solid-lightgrey);
  border-radius: 10px;
  /* padding: 10px 10px 10px 20px; */
  padding: 10px 10px 10px 0px;
}

.pricing-div .ant-checkbox {
  margin-left: 35px;
}

.create-user .custom-upload-wrapper .ant-upload,
.create-role .custom-upload-wrapper .ant-upload {
  width: 100% !important;
}

.invoicing-input {
  width: 100% !important;
  height: 42px !important;
}

.version-button {
  margin-top: 15px;
  margin-right: 10px;
}

.association-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkergrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  padding: 12px 16px !important;
}

.association-table .ant-table-thead > tr > th:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.association-table .ant-table .ant-table-body table {
  border-spacing: 0 7px;
  margin-top: -7px;
}

.association-table .ant-table-cell .name {
  text-align: left;
  padding-left: 10px;
}

.association-table .ant-table-cell .name .ant-image {
  margin-right: 10px;
}

.association-table .ant-table-container,
.system-setup-table .ant-table table {
  border: 0 !important;
}

.association-table.ant-table-tbody > tr > td {
  color: var(--color-table-data);
  font-weight: 600;
  font-size: 12px;
  /* text-align: center; */
  /* padding: 0 !important; */
}

.association-table .ant-table-tbody .ant-table-cell {
  width: 10%;
  padding: 12px 14px !important;
  background-color: var(--color-solid-white);
}

.association-table .ant-table-body > table > tbody > tr > td:not(:last-child) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.association-table .ant-table {
  background: transparent !important;
}

.association-table td:first-child,
.association-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.association-tablethead tr td:last-child {
  display: none !important;
}

.association-table.ant-table-thead > tr > th:nth-last-child(2) {
  border-inline-end: 1px solid var(--color-border) !important;
}

.association-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.association-table td:last-child,
.association-table th:last-child,
.association-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.association-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

.association-table .ant-table-tbody > tr > td:not(:first-child) {
  text-align: left;
}

.custom-radio {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
}

.custom-radio.checked {
  background-color: #007bff;
  color: #fff;
}

.association-radio-status {
  background-color: var(--color-solid-white);
  border: 1px solid var(--color-solid-lightgrey);
  border-radius: 10px;
  padding: 5px 10px 5px 20px;
}

.association-radio-status .ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.association-radio-status .ant-radio-wrapper > span {
  color: var(--color-solid-darkergrey);
}

.add-member-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px 10px 5px 8px;
}

#notes-documents-sys-setup {
  background: var(--color-solid-white);
  padding: 5px;
  border-radius: 0px 20px 20px 20px;
  overflow: auto;
  height: calc(100vh - 164px);
}

.transition-height {
  transition: height 0.4s ease-in-out;
}

.maximize {
  height: 340px;
}

.minimize {
  height: 40px;
}

/* .create-role .ant-form-item {
  margin-bottom: 10px;
} */

.invoicing-form-item {
  padding: 32px 30px;
}

.edit-invoice-entity-div {
  background-color: var(--color-solid-white);
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  /* padding: 0px 0px 15px 0px; */
  /* margin-top: 40px; */
  position: relative;
}

.commercial-days-costs-input {
  padding: 0 !important;
  font-size: 13px !important;
  width: unset !important;
  height: 30px !important;
  border-radius: 0 !important;
}

.commercial-days-costs-input .ant-input-number-input:disabled {
  background-color: #f0f0f0 !important;
  border-radius: 0 !important;
  cursor: default !important;
}

.commercial-days-costs-input .ant-input-number-handler-wrap {
  opacity: 1 !important;
}

.commercial-days-costs-input .ant-input-number-handler-up:hover,
.commercial-days-costs-input .ant-input-number-handler-down:hover {
  background-color: #007bff !important;
  height: 40% !important;
}

.commercial-days-costs-input .ant-input-number-handler-up:hover > span,
.commercial-days-costs-input .ant-input-number-handler-down:hover > span {
  color: #fff !important;
}

.formlayout-div {
  background-color: var(--color-solid-white);
  border: 1px solid var(--color-solid-lightgrey);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 3px 3px 0px 0px;
}
