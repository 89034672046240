.selected-contract-content {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 60px);
  padding-top: 5px;
}

.search-contract-content {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 60px);
  overflow-x: hidden;
  padding: 30px 50px 30px 50px;
}

.search-contract-content .recent-search-main {
  padding-top: 30px;
  padding-bottom: 30px;
}

.search-contract-content .recent-search {
  padding: 10px 15px;
  width: 25%;
  /* background-color: var(--color-solid-white); */
  border-radius: 24px;
  border: none;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}

.search-contract-content .recent-search .ant-input {
  background: transparent !important;
  color: var(--color-solid-black) !important;
}

.search-contract-content .recent-search:hover,
.search-contract-content .recent-search:active,
.search-contract-content .recent-search:checked,
.search-contract-content .recent-search:visited,
.search-contract-content .recent-search:focus {
  /* border: 1px solid var(--color-solid-darkgrey) !important; */
  border: none;
  box-shadow: none !important;
}

.search-contract-content .render-items {
  padding: 15px;
  border-bottom: 1px solid var(--color-solid-lightgrey);
}

.approved {
  background-color: #03a800;
  padding: 1px 9px;
  border-radius: 5px;
}
.rejected {
  background-color: #f2473b;
  padding: 1px 9px;
  border-radius: 5px;
}
.current-approver {
  background-color: #0177fb;
  padding: 1px 9px;
  border-radius: 5px;
}
.queue {
  background-color: #626166;
  padding: 1px 9px;
  border-radius: 5px;
}

.skipped {
  background-color: #7f00ff;
  padding: 1px 9px;
  border-radius: 5px;
}

.docusign {
  background-color: var(--color-solid-orange);
  padding: 1px 9px;
  border-radius: 5px;
}

.flow-text {
  /* font-family: "Heebo", sans-serif !important; */
}
