.dropdown-menu-item {
  display: block;
  max-width: 370px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-search {
  inset: "100px 462px auto auto" !important;
  max-width: "400px" !important;
}
#searchContainer {
  padding: "20px";
  background-color: #fff;
  box-shadow: "0px 4px 10px rgba(0, 0, 0, 0.1)";
  position: "absolute";
  top: "50px";
  left: "50%";
  transform: "translateX(-50%)";
  width: "400px";
  border-radius: "8px";
  z-index: 1000;
}
.closeIcon {
  font-size: "18px";
  cursor: "pointer";
  float: "right";
  color: "gray";
}
#searchInput {
  margin-top: 10px;
  width: 100%;
}

.global-search-layout {
  /* background-color: var(--color-solid-backgroundgrey);
  position: fixed;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 65px;
  left: 80px; */
  /* border: 1px solid #ccc; */
  background-color: #f9f9f9;
  /* padding: 20px; */
  font-size: 13px;
}

.global-search-layout .global-search {
  background-color: var(--color-solid-white);
  /* position: fixed; */
  /* width: calc(100vw - 40px); */
  height: calc(100vh - 50px);
  z-index: 999;
  /* border-radius: 15px; */
}

.global-search-layout .global-search .scrollable-search-result {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 160px);
}

.global-search .top-search-box {
  height: 35px;
  border-radius: 20px;
  border: 1px solid #0177fb;
  cursor: pointer;
  width: 400px;
}

.global-search .top-search-box .ant-input {
  color: #0177fb;
}

.global-search .top-search-box .ant-input::placeholder {
  color: var(--color-solid-darkerblue);
}

.global-search .search-type-menu {
  border: none;
}

.global-search .search-type-menu .ant-menu-item-selected::after,
.global-search .search-type-menu .ant-menu-item-active::after {
  display: none;
}

.global-search .search-type-menu .ant-menu-item {
  background-color: var(--color-solid-darkergrey);
  margin-right: 20px;
  padding: 0px 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: var(--color-solid-white);
}

.global-search .search-type-menu .ant-menu-item-selected,
.global-search .search-type-menu .ant-menu-item-selected:hover {
  background-color: var(--color-solid-darkerblue);
  margin-right: 20px;
  padding: 0px 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: var(--color-solid-white);
}

.global-search
  .search-type-menu
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: var(--color-solid-darkergrey);
  margin-right: 20px;
  padding: 0px 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: var(--color-solid-white);
}
