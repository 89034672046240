#action-management-id {
  padding: 10px 25px;
  background: var(--color-solid-backgroundgrey);
  height: calc(100vh - 60px);
}

.action-div-head {
  background-color: var(--color-solid-whitegrey);
  height: calc(100vh - 135px);
  overflow-y: scroll;
  border-radius: 10px;
}

.action-div-head-view {
  background-color: var(--color-solid-whitegrey);
  height: calc(100vh - 190px);
  overflow-y: scroll;
  border-radius: 10px;
}

.action-div-head-view-instance {
  background-color: var(--color-solid-white);
  height: calc(100vh - 190px);
  overflow-y: scroll;
  border-radius: 10px;
}

.action-body {
  background-color: var(--color-solid-white);
  border-radius: 8px;
  overflow: hidden;
}

.action-body-head {
  background-color: var(--color-solid-white);
  /* height: calc(100vh - 350px); */
  /* overflow-y: scroll; */
  border-radius: 20px;
  margin: 10px 30px;
}

.action-new-div .create-entity-div .view-supplier-hr {
  border-top: 0.1rem solid var(--color-solid-lightgrey);
}

.action-no-border {
  border: none;
  box-shadow: none !important;
}
.action-instance-tab .ant-tabs-nav-wrap {
  justify-content: center;
}

.action-instance-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.action-instance-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.action-instance-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.action-instance-tab .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
}

.action-instance-tab .ant-tabs-tab {
  padding: 0 !important;
}

.action-instance-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.action-instance-tab .ant-tabs-tab-btn {
  width: 180px;
}

#ss-filter-tabs .ant-tabs-tab-btn {
  width: unset;
}

.action-breadcrumb {
  border-radius: 7px;
  padding: 8px !important;
  color: var(--color-solid-darkgrey);
  background-color: var(--color-solid-white);
}

#action-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  /* color: var(--color-solid-darkgrey) !important; */
  color: var(--color-solid-darkergrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 13px;
  text-align: center !important;
  padding: 5px 16px !important;
}

.create-root-action-path-text {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
  color: #474747;
}

/* action-no-border .ant-picker-focused.ant-picker {
  box-shadow: none !important;
} */

.action-no-border .ant-picker-input > input {
  text-align: center !important;
}

.custom-trigger-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.custom-end-date:hover {
  color: var(--color-solid-darkblue);
}

.trigger-dropdown .ant-dropdown-menu-item {
  border-radius: 14px !important;
  background: var(--color-solid-white) !important;
  border: 1px solid var(--color-solid-darkerblue) !important;
}

.trigger-dropdown {
  background: none !important;
  box-shadow: none !important;
}

.trigger-dropdown .ant-dropdown-menu-item-selected {
  background: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
}

.action-search {
  height: calc(100vh - 138px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.comments-div {
  height: 12rem;
  overflow: scroll;
  margin: 10px 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column-reverse;
}

.comments-align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comments-align-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comments-new {
  width: fit-content;
  border: 2px solid rgb(67, 173, 67);
  border-radius: 10px;
  padding: 6px;
  max-width: 40rem;
}

.comments-old {
  width: fit-content;
  border: 2px solid var(--color-solid-darkerblue);
  border-radius: 10px;
  padding: 6px;
  max-width: 40rem;
}

.action-notes-div {
  background-color: var(--color-solid-white);
  padding: 5px;
  /* height: 300px;  */
  border-radius: 10px; /* Adjust the border-radius to control the roundness of the corners */
  border: 1px solid var(--color-solid-lightgrey);
}

.instance-notes-input {
  background-color: #f1f1f1;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
}

.instance-notes-input .ant-input {
  background: transparent;
}

.instance-notes-icons {
  display: flex;
  margin-left: auto;
}

.notes-header {
  padding: 10px;
  border-bottom: 1px solid var(--color-solid-lightgrey);
}

.author-data {
  font-size: 12px;
  color: var(--color-solid-darkergrey);
}
