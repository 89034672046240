.contract-pipeline {
  /* margin: 15px; */
  padding: 10px 25px;
  background: var(--color-solid-backgroundgrey);
  height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.pipeline-click {
  /* padding-top: 5px; */
  height: calc(100vh - 60px);
  background: var(--color-solid-backgroundgrey);
  overflow-x: hidden;
}

.ant-table-column-sorter {
  display: none !important;
}

.ant-table-column-sorters .ant-table-column-title .sorted-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  /* font-family: "Heebo", sans-serif !important; */
  text-align: center;
}

#sys-setup-id {
  padding: 10px 25px;
  background: var(--color-solid-backgroundgrey);
  height: calc(100vh - 50px);
}

.contract-pipeline .switch-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2684ff;
}

.contract-pipeline .switch-text > p {
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 1px;
}

.contract-pipeline .switch-handler {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-pipeline .ant-switch-checked {
  background-color: var(--color-solid-switchgreen) !important;
}

.contract-pipeline-table {
  margin: 10px 0 0 0;
}

.contract-pipeline-table .ant-table-thead > tr > th {
  background: var(--color-lightshade-blue) !important;
  color: var(--color-solid-darkgrey) !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 16px !important;
}

.contract-pipeline-table .ant-table-thead > tr > th:not(:last-child) {
  /* border-inline-end: none !important; */
  border-inline-end: 1px solid var(--color-border) !important;
}

.contract-pipeline-table .ant-table .ant-table-body table {
  border-spacing: 0 7px;
  margin-top: 0px;
}

.contract-pipeline-table .ant-table-container,
.contract-pipeline-table .ant-table table {
  border: 0 !important;
}

/* .contract-pipeline-table
  .ant-table
  .ant-table-body
  .ant-table-tbody
  .ant-table-row {
  background: var(--color-solid-white);
  cursor: pointer;
} */

.contract-pipeline-table .ant-table-tbody > tr > td {
  color: var(--color-table-data);
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  font-weight: 600;
  background: var(--color-solid-white);
  /* border: none !important; */
}

.contract-pipeline-table .ant-table-tbody > tr > td:nth-child(2) {
  color: var(--color-solid-darkblue);
}

.contract-pipeline-table .ant-table-tbody .ant-table-cell {
  padding: 12px 16px !important;
}

.contract-pipeline-table .ant-table {
  background: transparent !important;
}

.contract-pipeline-table td:first-child,
.contract-pipeline-table th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.contract-pipeline-table thead tr td:last-child {
  display: none !important;
}

.contract-pipeline-table .ant-table-thead > tr > th:nth-last-child(2) {
  /* border-radius: 0 10px 10px 0 !important; */
  /* border-inline-end: 1px !important; */
  border-inline-end: 1px solid var(--color-border) !important;
}

.contract-pipeline-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.contract-pipeline-table td:last-child,
.contract-pipeline-table th:last-child,
.contract-pipeline-table
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.contract-pipeline-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--color-lightshade-white) !important;
}

.contract-pipeline-table .collapse-details {
  width: 100%;
  color: var(--color-solid-black);
  font-weight: 500;
  padding: 15px;
}

.contract-pipeline-table .collapse-details h4 {
  color: var(--color-solid-darkgrey) !important;
  font-weight: 600;
  margin-bottom: 20px;
}

.contract-pipeline-table .collapse-details p {
  margin-bottom: 15px;
}

.contract-pipeline-table .ant-table-thead > tr > td {
  background-color: var(--color-lightshade-blue);
}

.contract-pipeline .filter-div {
  z-index: 9;
  margin-top: 7px;
  background-color: var(--color-solid-white);
  border-radius: 12px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 220px;
  height: 280px;
  position: absolute;
}

.contract-pipeline .filter-inner-div {
  z-index: 9;
  margin-top: 7px;
  background-color: var(--color-solid-white);
  border-radius: 12px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 220px;
  height: fit-content;
  position: absolute;
}

.contract-pipeline .filter-inner-div-1 {
  z-index: 9;
  margin-top: 7px;
  background-color: var(--color-solid-white);
  border-radius: 12px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  width: 300px;
  height: fit-content;
  position: absolute;
}

.contract-pipeline .filter-select {
  width: 100%;
}

.contract-pipeline .filter-select .ant-select-selector {
  border-width: 0 0 1px 0;
  width: 100%;
}

.contract-pipeline .filter-inner-div .ant-checkbox-group {
  display: block !important;
  width: 100% !important;
  height: 250px;
  overflow: scroll;
}

.contract-pipeline .filter-inner-div .ant-checkbox-group .ant-checkbox-wrapper {
  display: flex !important;
  width: 100% !important;
  margin-inline-start: 0;
  padding: 10px;
}

.contract-pipeline .filter-inner-div-1 .range-picker {
  margin: 30px 10px;
}

.contract-pipeline .filter-inner-div .filter-title,
.contract-pipeline .filter-inner-div-1 .filter-title {
  padding: 10px;
  border-bottom: 1px solid var(--color-border);
  font-weight: 550;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  vertical-align: middle;
}

.contract-pipeline .filter-inner-div .filter-title span:first-child,
.contract-pipeline .filter-inner-div-1 .filter-title span:first-child {
  padding-top: 3px;
}

.contract-pipeline .filter-inner-div .close-icon,
.contract-pipeline .filter-inner-div-1 .close-icon {
  padding: 0px 4px 1px 4px;
  border-radius: 100%;
  font-size: 13px;
  border: 2px solid var(--color-border);
  cursor: pointer;
  color: var(--color-border);
}

.contract-pipeline .filter-white-space-head {
  display: flex;
  align-items: center;
  /* background-color: var(--color-solid-white); */
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  width: 100%;
  /* min-height: 38px; */
}

.contract-pipeline .filter-white-space {
  overflow-x: scroll;
  white-space: nowrap;
  /* padding: 5px; */
  scroll-margin: 3px 0 !important;
}

.contract-pipeline .filter-white-space .placeholder-text {
  padding-left: 10px;
}

.contract-pipeline .filter-white-space::-webkit-scrollbar {
  height: 3px;
}

.contract-pipeline .filter-white-space-icons {
  justify-content: space-evenly;
}

.contract-pipeline .placeholder-text {
  color: var(--color-solid-darkgrey);
  padding-left: 10px;
}

.contract-pipeline .save-dialog-box {
  right: 0;
  margin-top: 6px !important;
}

.contract-pipeline .save-dialog-box .inner-div {
  margin: 10px 5%;
  width: 90%;
}

.contract-pipeline .save-dialog-box .inner-div label {
  font-size: 13px;
  color: var(--color-solid-darkgrey);
}

.contract-pipeline .save-dialog-box .ant-select {
  width: 100%;
  margin: 5px 0 10px 0;
}

.contract-pipeline .save-dialog-box .buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 5px;
}

.contract-pipeline .save-dialog-box .buttons p {
  color: var(--color-solid-darkblue);
  cursor: pointer;
  font-weight: 600;
}

.contract-pipeline .filter-image {
  padding: 7px !important;
  border-radius: 100%;
  font-size: medium;
  /* border: 1px solid var(--color-border); */
  /* background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  ); */
  /* background-color: var(--color-solid-white); */
  position: relative;
}

.contract-pipeline .saved-filter-image {
  /* padding: 9px 8px 7px 8px !important; */
  padding: 5px !important;
  width: 30px;
  border-radius: 100%;
  border: 1px solid var(--color-border);
  /* background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  ); */
  background-color: var(--color-solid-white);
  position: relative;
}

.contract-pipeline .ant-badge .ant-badge-count {
  background-color: var(--color-solid-darkblue);
  transform: translate(20%, -1%);
  min-width: 14px;
  height: 14px;
  font-size: 10px;
  line-height: 14px;
}

.contract-pipeline .pipeline-search {
  height: 38px;
  padding: 0px 20px;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
}

.contract-pipeline .pipeline-search:focus {
  box-shadow: none;
  border: 0;
}

.contract-pipeline .expand-image {
  padding: 10px 9px 8px 9px !important;
  width: 34px;
  border-radius: 100%;
  border: 1px solid var(--color-border);
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  );
  position: relative;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-nav,
.contract-pipeline .ss-saved-filter-tabs .ant-tabs-nav {
  margin-bottom: 5px;
  width: 100% !important;
  border-bottom: none !important;
}

.contract-pipeline
  .ss-saved-filter-tabs
  .ant-tabs-nav
  .ant-tabs-tab-active::before,
.contract-pipeline
  .ss-saved-filter-tabs
  .ant-tabs-nav
  .ant-tabs-tab-active::after {
  display: none !important;
}

.contract-pipeline .ss-saved-filter-tabs .ant-tabs-nav-wrap {
  padding: 0 !important;
}

.contract-pipeline .ss-saved-filter-tabs .ant-tabs .ant-tabs-tab-active {
  border: none !important;
}

.contract-pipeline .ss-saved-filter-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background: none !important;
  border: none !important;
  transition: none !important;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-nav-list {
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
  border: none !important;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-tab,
.contract-pipeline .ss-saved-filter-tabs .ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
  padding: 0;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-nav > div:nth-of-type(1),
.contract-pipeline .ss-saved-filter-tabs .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-tab .ant-tabs-tab-btn,
.contract-pipeline .ss-saved-filter-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 6px 0px !important;
  color: var(--color-solid-black) !important;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-tab-active .ant-tabs-tab-btn,
.contract-pipeline
  .ss-saved-filter-tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn {
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
}

.contract-pipeline .saved-filter-tabs .ant-tabs-ink-bar,
.contract-pipeline .ss-saved-filter-tabs .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.contract .pipeline-breadcrumb {
  border-radius: 10px;
  /* border: 1px solid var(--color-border); */
  /* margin-bottom: 20px; */
  padding: 6px 20px 6px 20px !important;
  /* border: 1px solid var(--color-border); */
  background: var(--color-solid-white);
  color: var(--color-solid-darkgrey);
}

.path-text-pipeline {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
  color: var(--color-solid-black);
  cursor: pointer;
}

.path-text-pipeline:hover {
  color: var(--color-solid-darkblue) !important;
}

.pipeline-breadcrumb button {
  padding: 5px 11px;
  line-height: 14px;
  border-radius: 100%;
  border: 0;
  margin: 0 10px;
  background-color: var(--color-lightshade-grey);
}

.contract-pipeline .expand-contract-icon {
  width: 30px;
  height: 30px;
  border: transparent;
  transition: border 0.3s ease;
}

.contract-pipeline .expand-contract-icon:hover {
  border: 1px solid var(--color-solid-darkergrey);
  border-radius: 100%;
}

.white-space-head {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-solid-white);
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.process-flow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.process-flow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 65px;
  /* background-color: #d0d7f5; */
  background-color: var(--color-solid-lightgrey);
  /* clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%); */
  transform: translateY(-50%);
  z-index: 0;
}

.step {
  position: relative;
  /* background-color: #4478c4; */
  background-color: var(--color-solid-backgroundblue);

  color: white;
  text-align: center;
  font-size: 0.8rem;
  /* font-weight: bold; */
  padding: 5px 30px;
  border-radius: 5px;
  z-index: 1;
  margin: 0 5px;
  min-width: 110px;
  line-height: 1.1;
  color: black;
}

.step br {
  display: block;
  line-height: 1.2;
}
