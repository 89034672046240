.contract-tab-layout {
  overflow-x: hidden !important;
}

#contract-refactor-tab,
#contract-tab-rearrange {
  background: var(--color-solid-backgroundgrey);
}

.contract-tab-divider {
  height: 20px !important;
  width: 2px !important;
  border-radius: 5px !important;
  border-inline-start: 0.14rem solid var(--color-solid-lightgrey) !important;
}

#contract-refactor-tab .ant-tabs-nav,
#contract-tab-rearrange .ant-tabs-nav {
  margin: 0 !important;
  padding-left: 15px !important;
  background-color: var(--color-solid-white) !important;
}

#contract-refactor-tab .ant-tabs-nav::before,
#contract-tab-rearrange .ant-tabs-nav::before {
  border: none !important;
}

#contract-refactor-tab .ant-tabs-nav-add,
#contract-tab-rearrange .ant-tabs-nav-add {
  border: 1px solid transparent !important;
  min-width: 25px !important;
  min-height: 25px !important;
  max-width: 25px !important;
  max-height: 25px !important;
  border-radius: 6px !important;
  padding: 2px !important;
  color: var(--color-solid-darkgrey) !important;
  font-size: 14px !important;
  margin: 6px 0 0 0 !important;
}

#contract-refactor-tab .ant-tabs-nav-add:hover,
#contract-tab-rearrange .ant-tabs-nav-add:hover {
  border: 1px solid var(--color-solid-lightgrey) !important;
}

#contract-refactor-tab .ant-tabs-nav-operations,
#contract-tab-rearrange .ant-tabs-nav-operations {
  margin-right: 20px !important;
}

#contract-refactor-tab .ant-tabs-nav-list,
#contract-tab-rearrange .ant-tabs-nav-list {
  margin-left: 5px !important;
}

#contract-refactor-tab .sortable-contract-tab,
#contract-tab-rearrange .sortable-contract-tab {
  display: flex !important;
  align-items: center !important;
  user-select: none !important;
  transition: all 0.2s ease !important;
}

#contract-refactor-tab .sortable-contract-tab:hover,
#contract-tab-rearrange .sortable-contract-tab:hover {
  color: var(--color-solid-darkerblue);
}

#contract-refactor-tab .sortable-contract-tab-title {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  text-shadow: 0 0 0.8px currentColor;
}

#contract-refactor-tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--color-solid-darkergrey) !important;
}

#contract-refactor-tab .ant-tabs-tab,
#contract-refactor-tab .ant-tabs-tab-active,
#contract-tab-rearrange .ant-tabs-tab,
#contract-tab-rearrange .ant-tabs-tab-active {
  /* color: var(--color-solid-darkergrey) !important; */
  border-radius: 0 !important;
  border: none !important;
  margin: 0 !important;
  /* border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; */
  min-width: 160px !important;
  position: relative !important;
  /* transition: none !important; */
}

#contract-refactor-tab .ant-tabs-tab-disabled,
#contract-refactor-tab .ant-tabs-tab-with-remove,
#contract-tab-rearrange .ant-tabs-tab-disabled,
#contract-tab-rearrange .ant-tabs-tab-with-remove {
  color: var(--color-solid-darkergrey) !important;
  position: relative !important;
  /* transition: none !important; */
}

#contract-refactor-tab .ant-tabs-tab {
  background-color: var(--color-solid-white) !important;
}

#contract-refactor-tab .ant-tabs-tab-active {
  background-color: var(--color-solid-backgroundgrey) !important;
}

#contract-refactor-tab .ant-tabs-tab-disabled {
  min-width: 10px !important;
  height: 36px !important;
  border-radius: 20px !important;
  padding: 0 !important;
  margin: 0px 0px !important;
  cursor: default !important;
  background-color: var(--color-solid-white) !important;
}

#contract-refactor-tab .ant-tabs-tab-disabled:hover {
  background-color: transparent !important;
}

#contract-refactor-tab .ant-tabs-tab-with-remove {
  min-width: 160px !important;
  border-radius: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  /* border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; */
}

/* #contract-refactor-tab .ant-tabs-tab-disabled:before,
#contract-refactor-tab .ant-tabs-tab-disabled:after {
  display: none !important;
}

#contract-refactor-tab .ant-tabs-tab:before,
#contract-refactor-tab .ant-tabs-tab:after,
#contract-refactor-tab .ant-tabs-tab-active:before,
#contract-refactor-tab .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 15px;
  width: 25px;
  bottom: 0;
  transition: none !important;
}

#contract-refactor-tab .ant-tabs-tab:before {
  left: -25px;
  border-radius: 0 0 7px 0;
  box-shadow: 12px 0 0 0 white;
}

#contract-refactor-tab .ant-tabs-tab:after {
  right: -25px;
  border-radius: 0 0 0 7px;
  box-shadow: -12px 0 0 0 white;
}

#contract-refactor-tab .ant-tabs-tab-active:before {
  left: -25px;
  border-radius: 0 0 7px 0;
  box-shadow: 12px 0 0 0 var(--color-solid-backgroundgrey);
}

#contract-refactor-tab .ant-tabs-tab-active:after {
  right: -25px;
  border-radius: 0 0 0 7px;
  box-shadow: -12px 0 0 0 var(--color-solid-backgroundgrey);
} */

.fade-in-left {
  animation: fadeInLeft 0.3s ease-in forwards;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

#contract-tab-rearrange .ant-tabs-tab {
  background-color: transparent !important;
}

#contract-tab-rearrange .ant-tabs-tab-disabled {
  min-width: 10px !important;
  height: 36px !important;
  border-radius: 20px !important;
  padding: 0 !important;
  margin: 0px 0px !important;
  cursor: default !important;
  background-color: transparent !important;
}

#contract-tab-rearrange .ant-tabs-tab-disabled:hover {
  background-color: transparent !important;
}
