.common_succeess_model .ant-modal-content {
  border-radius: 18px !important;
}

.res-modal-title {
  font-weight: bold;
  font-size: 17px;
}

.modal-info-btn {
  width: 30% !important;
  border-radius: 18px !important;
}
