.auth-bg {
  position: relative;
  /* background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red); */
  /* background: conic-gradient(from 90deg, #fff, #000); */
  width: inherit;
}

.auth-card {
  background: var(--color-solid-white);
  width: 45%;
  margin: auto;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 13px;
  border: 2px solid var(--color-border);
  text-align: center;
}

.auth-page-bg {
  background-image: url("../images/bg/auth_bg.svg");
  /* filter: blur(500px); */
  /* opacity: 0.1; */
  min-height: 100vh;
}

.verification-msg {
  color: var(--color-success);
  font-weight: 600;
  font-size: 16px;
}

.auth-head-msg {
  color: var(--color-solid-darkblue);
  font-weight: 600;
  font-size: 16px;
}

.regenerate-msg {
  color: var(--color-solid-yellow);
  font-weight: 600;
  font-size: 16px;
}

.set-password-btn,
.set-password-btn:hover {
  height: 40px;
  box-shadow: none !important;
  background: var(--color-success) !important;
  border: none !important;
  border-radius: 13px !important;
  color: var(--color-solid-white) !important;
  font-weight: 600;
}

.regenerate-btn,
.regenerate-btn:hover {
  height: 40px;
  box-shadow: none !important;
  background: var(--color-solid-yellow) !important;
  border: none !important;
  border-radius: 13px !important;
  color: var(--color-solid-white) !important;
  font-weight: 600;
}

.pass-checklist li:first-child {
  margin-top: 15px !important;
}

.pass-checklist {
  padding-left: 37px !important;
}

.set-password-input label {
  font-weight: 600;
}

.pass-checklist li:not(:first-child) {
  margin-top: 8px !important;
}

@media only screen and (max-width: 1200px) {
  .auth-card {
    background: var(--color-solid-white);
    width: 60%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 13px;
    border: 2px solid var(--color-border);
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .auth-card {
    background: var(--color-solid-white);
    width: 70%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 13px;
    border: 2px solid var(--color-border);
    text-align: center;
  }
}
