.tree-component-layout {
  height: calc(100vh - 87px);
  padding: 8px 20px;
  background-color: var(--color-solid-backgroundgrey);
}

.am-tree-layout {
  height: calc(100vh - 50px);
  padding: 8px 20px;
  background-color: var(--color-solid-backgroundgrey);
}

.contract-tree-splitter .ant-splitter-panel-hidden {
  transition: flex-basis 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.contract-tree-splitter .ant-splitter-bar-dragger {
  border-radius: 20px !important;
  transition: background-color 0.3s ease !important;
}

.contract-tree-splitter .ant-splitter-bar-dragger-disabled {
  background-color: var(--color-solid-darkgrey) !important;
}

.contract-tree-splitter .ant-splitter-bar-dragger::before {
  background-color: transparent !important;
}

.contract-tree-splitter .ant-splitter-bar-dragger:hover,
.contract-tree-splitter .ant-splitter-bar-dragger-active {
  background-color: var(--color-solid-darkerblue) !important;
}

.contract-tree-splitter .ant-splitter-bar-collapse-icon {
  width: 25px !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.contract-tree-splitter .ant-splitter-bar-collapse-bar {
  width: 25px !important;
  height: 30px !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  color: var(--color-solid-white) !important;
  background-color: var(--color-solid-darkerblue) !important;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.2s ease !important;
}

.breadcrumb-card {
  min-height: 32.433px !important;
  box-shadow: none !important;
}

.breadcrumb-card .ant-card-body {
  padding: 5px 6px 4px 6px !important;
}

.contract-tree-splitter .ant-splitter-bar-collapse-bar-end {
  margin-left: 10px !important;
}

.supplier-contract-tree-wrapper,
.supplier-contract-tree-wrapper-stretched,
.action-tree-wrapper,
.action-tree-wrapper-stretched {
  padding: 6px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  width: 100%;
}

.supplier-contract-tree-wrapper {
  height: calc(100vh - 200px);
}
.action-tree-wrapper {
  height: calc(100vh - 167px);
}

.supplier-contract-tree-wrapper-stretched {
  height: calc(100vh - 101px);
}

.action-tree-wrapper-stretched {
  height: calc(100vh - 125px);
}

.supplier-contract-tree .ant-tree-treenode {
  padding: 0px 5px 5px 0px !important;
  margin: 0 !important;
}

.supplier-contract-tree .ant-tree-switcher {
  margin-inline-end: 0px !important;
}

.supplier-contract-tree .ant-tree-switcher::before {
  height: 30px !important;
}

.supplier-contract-tree .ant-tree-switcher-icon {
  margin-top: 10px !important;
}

.supplier-contract-tree .ant-tree-node-selected {
  background-color: transparent !important;
}

.supplier-contract-tree .ant-tree-node-selected:hover {
  background-color: #f5f5f5 !important;
}

.supplier-contract-tree {
  background-color: #fff !important;
}

/* .supplier-contract-tree .ant-tree-node-content-wrapper {
  margin-left: 2px !important;
} */

.contract-table-cell {
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: text-shadow 0.2s ease;
}

.contract-table-cell:hover {
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.contract-type-card {
  /* margin-left: 5px !important; */
  box-shadow: none !important;
}

.contract-type-card .ant-card-body {
  padding: 6px 8px 6px 18px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: var(--color-solid-darkergrey) !important;
}

.contract-tree-empty,
.contract-tree-empty-stretched {
  margin: 0 !important;
  background-color: var(--color-solid-white) !important;
  border-radius: 10px !important;
}

.contract-tree-empty {
  height: calc(100vh - 200px) !important;
}

.contract-tree-empty-stretched {
  height: calc(100vh - 155px) !important;
}

.contract-tree-empty .ant-empty-image,
.contract-tree-empty-stretched .ant-empty-image {
  position: absolute !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.contract-tree-empty .ant-empty-description,
.contract-tree-empty-stretched .ant-empty-description {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
