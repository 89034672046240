.contract-level-card-col {
  flex-basis: 20%;
  width: 20%;
}

.create-contract-tree-col-1 {
  border: none !important;
}

.create-contract-tree-col-2 {
  background-color: var(--color-solid-white);
  padding: 15px 5px 10px 5px;
  border-radius: 15px;
  overflow: hidden;
}

.create-contract-tree-col-2 .tree-struc {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 395px);
  border: none !important;
}

.contract-level-card {
  /* text-align: center; */
  /* padding: 15px !important; */
  height: 125px !important;
}

.selectedCard {
  border: 2px solid var(--color-solid-darkblue);
}

.contract-level-card:hover {
  border: 2px solid var(--color-solid-lightblue);
  /* padding: 15px !important; */
}

.contract-level-card .ant-card-body {
  padding: 15px;
  border-radius: 0 0 8px 8px;
}

.selectedCardText {
  color: var(--color-solid-darkblue);
}

.card-select-text {
  padding: 35px 0px 25px 0px;
  color: #474747;
}

.contract-label {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-solid-darkgrey);
}

.contract-name {
  font-weight: 500;
  background-color: #f9f9f9;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.seleted-tree-detail,
.tree-struc {
  border-radius: 15px !important;
  min-height: 100%;
  height: calc(100vh - 55vh);
  overflow: auto;
  border: none !important;
}

.space-align-block {
  position: absolute;
  bottom: 20px;
  left: 40%;
  text-align: center;
  width: 100%;
}

.create-cancel-btn,
.create-cancel-btn:hover {
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-darkgrey);
  border-radius: 13px;
  color: var(--color-solid-darkgrey) !important;
  font-weight: 600;
  padding: 0px 30px 0px 30px;
}

.create-btn {
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-darkblue);
  border-radius: 13px;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
  padding: 0px 30px 0px 30px;
}

.confirmation-input {
  color: rgba(0, 0, 0, 0.9) !important;
  background: transparent !important;
  cursor: default !important;
}

.confirmation-form label {
  font-weight: 600;
  font-size: 12px !important;
}

.creation-flow-div {
  border-radius: 13px;
  border: 1px solid var(--color-solid-darkgrey);
  padding: 20px;
}

.ant-space .ant-space-item .flow-btn-top .flow-btn {
  /* background: rgba(228, 228, 228, 0.4) !important; */
  font-weight: 600;
  font-size: 12px;
  color: var(--color-solid-black);
  padding: 10px 15px;
  border-radius: 13px;
}

.ant-space .ant-space-item:not(:last-child) .flow-btn-top .flow-btn {
  background: rgba(228, 228, 228, 0.4) !important;
}

.ant-space .ant-space-item:last-child .flow-btn-top .flow-btn {
  background: var(--color-solid-darkblue);
  color: var(--color-solid-white);
}

.ant-space .ant-space-item:last-child .flow-btn-top .flow-btn .ant-image {
  display: none;
}

#flow-btn-bottom .flow-btn-new {
  background: var(--color-solid-darkblue);
  /* background-color: red; */
  font-weight: 600;
  font-size: 12px;
  color: var(--color-solid-white);
  padding: 10px 20px;
  border-radius: 13px;
}

.tree-data-search {
  border-radius: 10px !important;
  border: 1px solid var(--color-solid-darkgrey);
}

.contract .create-contract-div {
  /* padding: 25px 60px; */
  padding: 25px;
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 100%; */
}

.contract .create-contract-div .tree-data {
  padding: 5px 0px 5px 0px;
}

.contract .create-contract-div .ant-tree-title {
  margin-top: 2px;
  display: inline-block;
  font-size: 15px;
}

.contract .create-contract-div .ant-tree {
  background-color: transparent;
  max-width: 100%;
  /* overflow-x: scroll; */
}

.contract .create-contract-div .ant-tree .ant-tree-treenode {
  padding: 0px 0 10px 0;
  width: 100%;
  /* display: block; */
  display: inherit;
  white-space: nowrap;
}

.contract .create-content-div .ant-tree .ant-tree-treenode span {
  display: inline-block;
}

.contract .create-contract-div .ant-tree .ant-tree-node-content-wrapper {
  min-width: 100% !important;
  background-color: #fafafa;
  padding: 5px 30px 5px 30px;
}

.contract .create-contract-div .ant-tree .ant-tree-node-content-wrapper:hover {
  background: var(--color-solid-backgroundgrey);
}

.contract .create-contract-div .ant-tree .ant-tree-switcher {
  /* padding-top: 3px; */
  display: none;
}

.contract-authoring {
  margin-top: 10px;
}

.contract-div {
  background-color: #fafafa;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-box-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-lightgrey);
  padding: 10px 10px 0px 10px;
}

@keyframes ellipsis {
  0% {
    content: "   ";
  }
  14.29% {
    content: ".  ";
  }
  28.57% {
    content: ".. ";
  }
  42.86% {
    content: "...";
  }
  57.14% {
    content: "...";
  }
  71.43% {
    content: ".. ";
  }
  85.71% {
    content: ".  ";
  }
  100% {
    content: "   ";
  }
}

.ellipsis::after {
  content: "   ";
  animation: ellipsis 2s steps(7, end) infinite;
  display: inline-block;
  width: 3ch; /* width of one character */
  text-align: left;
}
