.report-input {
  width: 100% !important;
  height: 42px !important;
}

/* .inner-report-tab .ant-tabs-nav-wrap {
  justify-content: center;
} */

.inner-report-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.inner-report-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.inner-report-tab .ant-tabs-nav {
  margin-bottom: 3px !important;
}

.inner-report-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.inner-report-tab .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  border-radius: 24px;
  width: unset;
}

.inner-report-tab .ant-tabs-tab {
  padding: 0 !important;
}

.inner-report-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.inner-report-tab .ant-tabs-tab-btn {
  width: 180px;
}

#edit-report-scroll-section {
  height: calc(100vh - 182px);
}

#report-form-section {
  height: calc(100vh - 185px);
}

#create-report-form-section {
  height: calc(100vh - 130px);
}

#report-form-section .report-form-head {
  font-weight: 600;
  border: 1px solid var(--color-border);
  padding: 10px 15px;
  border-radius: 10px;
  color: var(--color-solid-darkergrey);
}

#report-form-section
  .org-supplier-label
  .ant-select-selector
  .ant-select-selection-search-input,
#report-form-section .ant-select-single {
  height: 42px !important;
}
#report-form-section .org-supplier-label .ant-select-selector {
  padding: 0px 0px 0px 20px !important;
  height: 42px !important;
}

#report-form-section .ant-picker {
  padding-left: 20px !important;
}

.price-profile-content
/* .ant-picker   */  /* i have comented this because it affect the rate card cover date picker */ {
  /* padding-left: 20px !important; */
}

.schedule-div {
  background-color: var(--color-solid-white);
  border: 1px solid var(--color-solid-lightgrey);
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
}

.schedule-div .ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.schedule-div .ant-radio-wrapper > span {
  color: var(--color-solid-darkergrey);
}

.report-btn {
  height: 40px !important;
  background-color: transparent;
  border: 1px solid var(--color-solid-darkerblue);
  color: var(--color-solid-darkerblue);
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px !important;
  font-weight: 600;
}

.report-btn:hover {
  background-color: var(--color-solid-darkerblue);
  border: 1px solid var(--color-solid-darkerblue);
  color: var(--color-solid-white) !important;
}

.schedule-btn {
  height: 40px !important;
  background-color: transparent;
  border: 1px solid var(--color-solid-darkestblue);
  color: var(--color-solid-darkestblue);
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px !important;
  font-weight: 600;
}

.schedule-btn:hover {
  background-color: var(--color-solid-darkestblue);
  border: 1px solid var(--color-solid-darkestblue);
  color: var(--color-solid-white) !important;
}

.download-btn {
  height: 35px !important;
  background-color: transparent;
  border: 1px solid var(--color-solid-darkestblue);
  color: var(--color-solid-darkestblue);
  border-radius: 20px !important;
  font-weight: 600;
}

.download-btn:hover {
  background-color: transparent;
  border: 1px solid var(--color-solid-darkestblue) !important;
  color: var(--color-solid-darkestblue) !important;
}

.filter-btn {
  height: 35px !important;
  background-color: var(--color-solid-darkerblue);
  border: 1px solid var(--color-solid-darkerblue);
  color: var(--color-solid-white) !important;
}

#report-spin {
  width: 100% !important;
  padding-top: 30px !important;
}

/* .report-cell .ant-table-tbody .ant-table-cell {
  padding: 20px !important;
} */

.filter-white-space-blank {
  display: flex;
  align-items: center;
  background-color: var(--color-solid-white);
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  width: 100%;
  min-height: 38px;
}

/* .ant-checkbox-group {
  display: block !important;
} */
